import { IContactsInfo } from '@modules/footer/ui/interface';

export const useEmptyFooterContacts = () => {
  const emptyInfo: IContactsInfo[] = [
    { path: '', title: 'Адрес: Информация скоро появится' },
    { path: '', title: 'Телефон: Информация скоро появится' },
  ];

  return emptyInfo;
};
