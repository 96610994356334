import { Link } from 'react-router-dom';

import { Space } from 'antd';

import { useSettingsHeader } from '@modules/header/services';

export const HeaderSettings: React.FC = () => {
  const settingItems = useSettingsHeader();
  return (
    <Space direction="vertical" className="p-4">
      {settingItems.map((item) => (
        <Link
          target="_blank"
          className="text-black"
          to={item.link}
          key={item.label}
        >
          {item.label}
        </Link>
      ))}
    </Space>
  );
};
