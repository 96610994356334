import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

// body (string): Email, will be prepended to the url.
interface IEmail {
  body?: string;
}

// image (string): An absolute link to the image that will be shared
interface IVk {
  image?: string;
}

interface IUseShareProps {
  url: string;
  email?: IEmail;
  title: string;
  vk?: IVk;
  round: boolean;
  size: number;
}

export const useShare = ({
  url,
  email,
  title,
  vk,
  size,
  round,
}: IUseShareProps) => {
  const social = [
    {
      button: (
        <EmailShareButton
          key="email"
          subject={title}
          body={email?.body}
          url={url}
        >
          <EmailIcon size={size} round={round} />
        </EmailShareButton>
      ),
    },
    {
      button: (
        <TelegramShareButton key="tg" title={title} url={url}>
          <TelegramIcon size={size} round={round} />
        </TelegramShareButton>
      ),
    },
    {
      button: (
        <VKShareButton key="vk" title={title} image={vk?.image} url={url}>
          <VKIcon size={size} round={round} />
        </VKShareButton>
      ),
    },
    {
      button: (
        <WhatsappShareButton key="whatsapp" title={title} url={url}>
          <WhatsappIcon size={size} round={round} />
        </WhatsappShareButton>
      ),
    },
    {
      button: (
        <FacebookShareButton key="facebook" title={title} url={url}>
          <FacebookIcon size={size} round={round} />
        </FacebookShareButton>
      ),
    },
    {
      button: (
        <TwitterShareButton key="twitter" title={title} url={url}>
          <TwitterIcon size={size} round={round} />
        </TwitterShareButton>
      ),
    },
  ];

  return social;
};
