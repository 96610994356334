import { useTranslation } from 'react-i18next';

import { Form, Input, message, Spin } from 'antd';

import { useAppSelector } from '../../../store/hooks';
import { IContacts } from '../domain/interfaces/ContactsState';
import { ContactsStateSelector } from '../domain/store/selector';
import { useSendMessage } from '../services/useSendMessage/useSendMessage';

const { TextArea } = Input;

export const ContactsForm: React.FC = () => {
  const [form] = Form.useForm();
  const { handleSubmitMessage } = useSendMessage();
  const { loading } = useAppSelector(ContactsStateSelector);

  const { t } = useTranslation();

  const onFinish = (values: any) => {
    handleSubmitMessage({ ...values, phone: `+${values.phone}` }).then(
      (value) => {
        if (value?.meta?.requestStatus === 'fulfilled') {
          message.info('Сообщение отправлено!');
        } else {
          message.error('Что-то пошло не так!');
        }
      },
    );
    form.resetFields();
  };

  return (
    <>
      {loading && <Spin spinning={loading} fullscreen />}
      <Form
        form={form}
        name="contacts"
        style={{ width: 'auto' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className="mt-10 mb-[140px]"
      >
        <Form.Item<IContacts>
          label={t('feedback.fullName')}
          name="name"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: 'Пожалуйста заоплните ваши ФИО!',
              type: 'string',
            },
          ]}
        >
          <Input
            placeholder={t('feedback.enterFullName')}
            className="rounded-[5px] p-[16px]"
          />
        </Form.Item>
        <Form.Item
          label={t('feedback.email')}
          name="email"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: 'Пожалуйста заоплните ваш email корректно!',
              type: 'email',
            },
          ]}
        >
          <Input
            placeholder={t('feedback.enterEmail')}
            className="rounded-[5px] p-[16px]"
          />
        </Form.Item>

        <Form.Item
          className="w-auto"
          label={t('feedback.phone')}
          name="phone"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder={t('feedback.enterPhone')}
            className="rounded-[5px] p-[16px] remove-arrow"
            onWheel={(e) => e.currentTarget.blur()}
            type="number"
          />
        </Form.Item>
        <Form.Item
          className="w-auto"
          label={t('feedback.messageTheme')}
          name="theme"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Введите тему сообщения!' }]}
        >
          <Input
            placeholder={t('feedback.enterMessageTheme')}
            className="rounded-[5px] p-[16px]"
          />
        </Form.Item>
        <Form.Item<IContacts>
          className="w-auto"
          label={t('feedback.message')}
          name="message"
          labelCol={{ span: 24 }}
          rules={[{ required: true, message: 'Введите сообщение!' }]}
        >
          <TextArea
            placeholder={t('feedback.enterMessage')}
            rows={4}
            className="h-[115px] w-full rounded-[5px] p-[16px] placeholder-shown:resize-none"
          />
        </Form.Item>
        <Form.Item>
          <button
            type="submit"
            className="w-full h-[63px] bg-primaryBlue text-white mt-[48px]"
          >
            {t('incidentReport.send')}
          </button>
        </Form.Item>
      </Form>
    </>
  );
};
