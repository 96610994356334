import React, { useEffect, useState } from 'react';

import { Pagination, Spin, Typography } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { InformationCard } from '@modules/home/ui/InformationCard';
import { InformationStateSelector } from '@modules/information/domain/store/selector';
import { getInformation } from '@modules/information/domain/store/slice';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const News: React.FC = () => {
  const [page, setPage] = useState(1);
  const { information, loading, totalInformation, selectedParams } =
    useAppSelector(InformationStateSelector);
  const { language } = useAppSelector(NavbarStateSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getInformation({ perPage: 10, lang: language, page, ...selectedParams }),
    );
  }, [page, language, selectedParams]);

  return (
    <div className="min-h-[666px]">
      {loading ? <Spin spinning={loading} fullscreen /> : null}
      {information.length ? (
        <div className="flex flex-col justify-between h-full">
          <div>
            {information.map((info) => (
              <div key={info.id} className="mt-[5px]">
                <InformationCard {...info} />
              </div>
            ))}
          </div>
          <Pagination
            pageSize={3}
            current={page}
            className="my-5"
            onChange={setPage}
            total={totalInformation}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <Typography className="text-[2rem]">Данные отсутствуют</Typography>
        </div>
      )}
    </div>
  );
};
