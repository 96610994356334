export enum AppRoutes {
  default = '/',
  notFound = '/not-found',
  monitoring = '/monitoring',
  naturalDisasters = '/natural-disasters',
  forecastMaterials = '/forecast-materials',
  aboutESKMP = '/about-ESKMP',
  contacts = '/contacts',
  allInformation = '/all-information',
  incidentReport = '/incident-report',
  disasterMap = '/disaster-map',
  maps = '/maps',
  allMaps = '/maps/all',
  news = '/news',
}
