import React from 'react';
import { useTranslation } from 'react-i18next';

import { AboutESKMP } from '@modules/aboutESKMP/ui/AboutESKMP';
import { Container } from '@modules/container';
import { SectionTitle } from '@modules/sectionTitle';

export const AboutPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionTitle paddingBottom="1rem" title={t('about.about-dmpchs')} />
      <AboutESKMP />
    </Container>
  );
};
