import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Layout, Typography } from 'antd';

import { Map } from '@modules/Map/ui/Map';
import L from 'leaflet';

import { useAppSelector } from '../../../store/hooks';
import { MapStateSelector } from '../domain/store/selector';

const { Content } = Layout;

const geoURL = process.env.REACT_APP_BASE_GEO_SERVER ?? '';

export const MapLayout: React.FC = () => {
  const { currentLayer } = useAppSelector(MapStateSelector);
  const [urlParams] = useSearchParams();

  // const fetchGeoNodeLayer = (layerName: string) => {
  //   if (!currentLayer) return;
  //   let geoNodeLayer;
  //   if (layerName.trim().startsWith('http')) {
  //     const url = layerName;
  //     // get params from url
  //     const params = new URLSearchParams(url);
  //     geoNodeLayer = L.tileLayer.wms(layerName, {
  //       layers: params.get('LAYERS') as string,
  //       transparent: true,
  //       format: (params.get('FORMAT') as string) ?? 'image/png',
  //       version: (params.get('VERSION') as string) ?? '1.3.0',
  //     });
  //   } else {
  //     // eslint-disable-next-line @typescript-eslint/no-shadow
  //     const options = {
  //       layers: layerName,
  //       transparent: true,
  //       format: 'image/png',
  //       version: '1.3.0',
  //     };
  //     geoNodeLayer = L.tileLayer.wms(geoURL, options);
  //   }
  //   geoNodeLayer.addTo(currentLayer);
  // };
  //
  // useEffect(() => {
  //   if (!currentLayer) return;
  //
  //   const geoLayers = Array.from(urlParams.values());
  //
  //   geoLayers.forEach((layer) => {
  //     fetchGeoNodeLayer(layer);
  //   });
  // }, [urlParams, currentLayer]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content>
        <Typography className="text-2xl font-semibold w-1/2">Карты</Typography>
        {urlParams.get('geoData') ? (
          <iframe
            src={urlParams.get('geoData') ?? ''}
            title="Карта"
            width="100%"
            style={{ minHeight: '100vh' }}
          />
        ) : (
          'Нет данных о карте'
        )}
      </Content>
    </Layout>
  );
};
