import { useTranslation } from 'react-i18next';

import { IContactsInfo } from '@modules/footer/ui/interface';

export const useAboutUsInfo = () => {
  const { t } = useTranslation();

  const aboutUsInfo: IContactsInfo[] = [
    { path: '/about-ESKMP', title: t('footer.about-dmpchs') },
    { path: '/contacts', title: t('footer.contacts') },
  ];

  return aboutUsInfo;
};
