import { useEffect } from 'react';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { UsefulResourcesStateSelector } from '@modules/home/domain/store/selector';
import { getRegions } from '@modules/home/domain/store/slice';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export const useSelectItems = () => {
  const dispatch = useAppDispatch();
  const { processes, regions } = useAppSelector(UsefulResourcesStateSelector);
  const { language } = useAppSelector(NavbarStateSelector);

  useEffect(() => {
    dispatch(getRegions({ params: { lang: language } }));
  }, [language]);

  const oopSelectorItems = processes.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const regionsSelectorITems = regions.map((item) => ({
    value: item,
    label: item,
  }));

  return {
    oop: [
      {
        label: 'Все происшествия',
        value: '',
      },
      ...oopSelectorItems,
    ],
    regions: [
      {
        label: 'Все регионы',
        value: '',
      },
      ...regionsSelectorITems,
    ],
  };
};
