import { useState } from 'react';

import { IUseModalSettings } from './interface';

export const useModalSettings: () => IUseModalSettings = () => {
  const [fontSize, setFontSize] = useState<string>('14');
  const [isThemeColored, setIsThemeColored] = useState<boolean>(true);

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFontSize = e.target.value;
    setFontSize(selectedFontSize);
    document.documentElement.style.fontSize = `${selectedFontSize}px`;
    document.body.style.fontSize = `${selectedFontSize}px`;
  };

  const handleChangeTheme = (themeType: boolean) => {
    setIsThemeColored(themeType);
    const rootElement = document.getElementById('root');
    if (!themeType) {
      if (rootElement) {
        rootElement.classList.add('monochromeTheme');
      }
    } else if (themeType) {
      if (rootElement) {
        rootElement.classList.remove('monochromeTheme');
      }
    }
  };
  return {
    isThemeColored,
    handleChangeTheme,
    fontSize,
    handleFontSizeChange,
  };
};
