/* eslint-disable global-require */
import { initReactI18next } from 'react-i18next';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export const i18nextLocal = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    debug: true,
    fallbackLng: 'ru',
    resources: {
      en: {
        translation: require('./config/locales/eng/translation.json'),
      },
      ru: {
        translation: require('./config/locales/ru/translation.json'),
      },
      kg: {
        translation: require('./config/locales/ky/translation.json'),
      },
    },
  });

export default i18next;
