import { useState } from 'react';

import {
  Col,
  Divider,
  Empty,
  Flex,
  Image,
  message,
  Space,
  Tooltip,
  Typography,
} from 'antd';

import { useNormalizeDate } from '@hooks/useNormalizeDate';
import { useShare } from '@modules/home/services';
import { IInformation } from '@modules/information/domain/interface/InformationState';
import { Icon } from '@ui-kit/icon';

const URL = process.env.REACT_APP_BASE_URL ?? '';

interface CurrentNewsProps {
  currentInformation: IInformation | null;
}

export const CurrentNews: React.FC<CurrentNewsProps> = ({
  currentInformation,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const shareArray = useShare({
    url: window.location.href,
    title: currentInformation?.title ?? '',
    email: { body: currentInformation?.content },
    size: 20,
    round: true,
  });

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(window.location.href);
      message.info('URL скопирован');
    } catch (e: any) {
      message.error(e.message);
    }
  };

  if (!currentInformation) {
    return (
      <Col flex={5}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Col>
    );
  }

  return (
    <Col flex={5}>
      <Flex justify="space-between">
        <Typography className="text-2xl font-semibold w-1/2">
          {currentInformation.title}
        </Typography>
        <Typography className="text-2xl font-semibold">
          {currentInformation.district}
        </Typography>
      </Flex>
      <Col className="flex items-center justify-between text-[#0000005E] font-semibold mt-4 mb-12">
        <Col>
          <Icon type="calendar" className="w-[16px]" />
          {useNormalizeDate(currentInformation.created ?? '')}
        </Col>
        <Col>
          <Space>
            <Tooltip
              open={open}
              title={
                <div className="flex justify-center items-center gap-2">
                  {shareArray.map((item) => item.button)}
                </div>
              }
              onOpenChange={handleOpenChange}
              placement="bottom"
              trigger={['click']}
            >
              <button
                type="button"
                className="bg-[#1E4161] text-white flex items-center px-[16px] py-[4px] rounded-lg gap-2"
              >
                <Typography className="font-light text-white">
                  Поделиться
                </Typography>
                <Icon type="newsShare" className="defaultIcons" />
              </button>
            </Tooltip>
            <button type="button" onClick={handleCopy}>
              <Icon
                type="copy"
                className="cursor-pointer w-[30px] defaultIcons"
              />
            </button>
          </Space>
        </Col>
      </Col>
      <Col>
        <Typography>{currentInformation.content}</Typography>
      </Col>
      <Divider />
      <Image.PreviewGroup>
        {currentInformation?.photo.map((item) => (
          <Image
            key={item.path}
            width={200}
            height={200}
            src={`${URL}/information/files?path=${item.path}`}
          />
        ))}
      </Image.PreviewGroup>
    </Col>
  );
};
