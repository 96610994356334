import React from 'react';

import { Spin } from 'antd';

export const Loading: React.FC = () => {
  return (
    <div className="flex justify-center py-10">
      <Spin size="large" />
    </div>
  );
};
