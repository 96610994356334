import { useTranslation } from 'react-i18next';

import { Container } from '@modules/container';
import { IncidentReportForm } from '@modules/reportIncident';
import { SectionTitle } from '@modules/sectionTitle';

export const IncidentReportPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SectionTitle
        title={t('breadcrumbs.incident-report')}
        fontWeight={500}
        fontSize={2}
      />

      <IncidentReportForm />
    </Container>
  );
};
