import React, { useEffect } from 'react';

import { Empty } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { Loading } from '@modules/loading';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ForecastMaterialsStateSelector } from '../domain/store/selector';
import { getForecastMaterialInfo } from '../domain/store/slice';

export const ForecastMaterialInformation = () => {
  const dispatch = useAppDispatch();
  const { forecastMaterialInfo, loading } = useAppSelector(
    ForecastMaterialsStateSelector,
  );
  const { language } = useAppSelector(NavbarStateSelector);

  useEffect(() => {
    dispatch(getForecastMaterialInfo({ params: { lang: language } }));
  }, [language]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="mt-[24px] mb-[80px]">
      <p className="leading-[24px] text-primaryGray">
        <div
          dangerouslySetInnerHTML={{
            __html: forecastMaterialInfo?.description ?? '',
          }}
        />
      </p>
    </div>
  );
};
