import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  DatePicker,
  Layout,
  Row,
  Segmented,
  Space,
  Table,
  Typography,
} from 'antd';

import { OppStatusEnum } from '@modules/Map/domain/interface/interface';
import { MapStateSelector } from '@modules/Map/domain/store/selector';
import {
  changeCurrentGeoData,
  changeOnLayer,
  getGeoData,
  getLayers,
  removeOnLayer,
  resetOnLayer,
} from '@modules/Map/domain/store/slice';
import { Map as UiMap } from '@modules/Map/ui/Map';
import { mapSelectItems, options } from '@modules/monitoring/contants';
import { SectionTitle } from '@modules/sectionTitle';
import { Dayjs } from 'dayjs';
import L from 'leaflet';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

interface MapLayersProps {
  mapHeight?: string | number;
}

export const MapLayers: React.FC<MapLayersProps> = ({ mapHeight }) => {
  const [oppLayers, setOppLayers] = useState<any[]>(mapSelectItems);

  const [filterDate, setFilterDate] = useState<
    Record<string, undefined | string>
  >({
    from: undefined,
    to: undefined,
  });

  const dispatch = useAppDispatch();
  const { layers, onLayer, currentLayer, mapCounts } =
    useAppSelector(MapStateSelector);

  console.log('mapCounts', currentLayer);
  const handleDateChange = (
    date: Dayjs,
    dateString: string | string[],
    from: string,
  ) => {
    const validDate = date?.format('YYYY/MM/DD');

    setFilterDate((prev) => ({ ...prev, [from]: validDate }));
  };

  const onEachFeature = (feature: any, layer: L.Layer) => {
    if (typeof feature?.properties === 'undefined') return;

    const mapData = feature?.properties;

    layer.bindPopup(() => {
      dispatch(
        changeCurrentGeoData({
          ...feature,
        }),
      );

      return `<p>${mapData?.type?.title ?? 'Неизвестно'}</p>`;
    });
  };

  const paintGeoJson = (id: string, data: any) => {
    if (!currentLayer) return;

    const geoData = data.map((item: any) => item.geo);
    const layer = L.geoJSON(geoData, {
      onEachFeature,
    }).addTo(currentLayer);

    dispatch(changeOnLayer({ [id]: layer }));
  };

  const checkboxOnClick = (id: string) => {
    if (!currentLayer) return;

    const findLayer = (layers as any).find((item: any) => item.id === id);

    if (onLayer[id] === true) {
      /* empty */
    } else if (onLayer[id]) {
      currentLayer.removeLayer(onLayer[id]);
      dispatch(removeOnLayer(id));
    } else {
      const type = findLayer.type === 'region' ? findLayer.type : 'opp_type';

      dispatch(changeOnLayer({ [id]: true }));
      dispatch(
        getGeoData({
          type,
          id: findLayer.id,
          status: OppStatusEnum.mapPublished,
        }),
      ).then((data: any) => {
        if (data.payload.length) {
          paintGeoJson(id, data.payload);
        } else {
          dispatch(removeOnLayer(id));
        }
      });
    }
  };

  const handleSegmentChange = (key: string) => {
    if (onLayer) {
      Object.keys(onLayer).forEach((id: string) => {
        if (onLayer[id]) {
          checkboxOnClick(id);
        }
      });
    }
    dispatch(getLayers({ type: key, date: filterDate }));
  };

  const validOppLayers: { label: string; value: string }[] = useMemo(() => {
    if ((layers as any)?.length)
      return (layers as any).map((item: any) => ({
        label: item.title,
        value: item.id,
      }));

    return [];
  }, [layers]);

  useEffect(() => {
    const oppOptions = validOppLayers.map((opp) => (
      <Button
        style={{
          whiteSpace: 'normal',
          height: 'auto',
        }}
        className={onLayer[opp.value] ? 'mapDefault active' : 'mapDefault'}
        onClick={() => checkboxOnClick(opp.value)}
        key={opp.value}
      >
        {opp.label}
      </Button>
    ));

    setOppLayers(oppOptions);
  }, [layers, onLayer]);

  useEffect(() => {
    dispatch(resetOnLayer());
    dispatch(getLayers({ type: 'region', date: filterDate }));
    handleSegmentChange('region');
  }, []);

  useEffect(() => {
    dispatch(getLayers({ type: 'region', date: filterDate }));
  }, [filterDate]);

  return (
    <div className="p-2">
      <Segmented
        options={options}
        block
        size="large"
        onChange={handleSegmentChange}
      />
      <Row style={{ margin: '20px 0' }} gutter={[10, 0]}>
        <Col>
          <SectionTitle title="Опасные природные процессы (обследования)" />
        </Col>
        <Col flex={1}>
          <Row gutter={[10, 10]} align="middle">
            <Col className="ml-auto">
              <Typography>Фильтрация: </Typography>
            </Col>
            <Col>
              <DatePicker
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString, 'from');
                }}
                placeholder="От"
              />
            </Col>
            <Col>
              <DatePicker
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString, 'to');
                }}
                placeholder="До"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Layout style={{ borderRadius: 15 }}>
        <UiMap mapHeight={mapHeight || '95vh'} collapseItems={oppLayers} />
      </Layout>
      <Space size="large" direction="vertical">
        <Row gutter={6}>
          {mapCounts.map((item: any) => (
            <Col key={item.id}>
              <Typography.Text>{item.title}</Typography.Text>
              <Typography.Text>{item.count}</Typography.Text>
            </Col>
          ))}
        </Row>
      </Space>
    </div>
  );
};
