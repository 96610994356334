import { axiosInstance } from '@libs/http';
import {
  FooterState,
  IContacts,
} from '@modules/footer/domain/interfaces/interface';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Api } from './api';
import { SliceConstants } from './sliceConstants';

const initialState: FooterState = {
  loading: false,
  contacts: [],
};

export const getContacts = createAsyncThunk(
  SliceConstants.GetContacts,
  async ({ lang }: { lang: string }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get<Awaited<IContacts[]>>(
        `${Api.contacts}?lang=${lang}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const FooterSlice = createSlice({
  name: 'FooterSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          contacts: payload,
        };
      })
      .addCase(getContacts.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getContacts.rejected, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export default FooterSlice.reducer;
