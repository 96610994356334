import { useNavigate } from 'react-router-dom';

import { Button, Result } from 'antd';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      className="notFound"
      status="404"
      title="404"
      subTitle="Страница находится на стадии разработки"
      extra={
        <Button
          className="bg-blue-500 text-white"
          onClick={() => navigate('/')}
          type="primary"
        >
          На главную
        </Button>
      }
    />
  );
};
