import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FooterStateSelector } from '@modules/footer/domain/store/selector';
import { getContacts } from '@modules/footer/domain/store/slice';
import { useAboutUsInfo } from '@modules/footer/services';
import { useEmptyFooterContacts } from '@modules/footer/services/useFooterData/useEmptyFooterContacts';
import { FooterInfoItem } from '@modules/footer/ui/FooterInfoItem';
import { NavbarStateSelector } from '@modules/header/domain/store/selector';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const Footer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(NavbarStateSelector);
  const { contacts } = useAppSelector(FooterStateSelector);
  const aboutUsInfo = useAboutUsInfo();
  const emptyInfo = useEmptyFooterContacts();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getContacts({ lang: language }));
  }, []);

  return (
    <div className="bg-[#ECECED] border-t-[4px] border-[#1C6AB1] border-solid flex flex-col">
      <div className="flex mx-0 lg:mx-auto pt-[42px] flex-col lg:flex-row justify-end">
        <div className="w-full flex lg:justify-center ">
          <FooterInfoItem
            title={t('footer.portal-information')}
            infoTypeArray={aboutUsInfo}
          />
        </div>
        <div className="w-full mb-[42px]">
          <FooterInfoItem
            title={t('footer.support')}
            infoTypeArray={contacts.length ? contacts : emptyInfo}
          />
        </div>
      </div>

      <div className="p-0 md:p-[64px] flex justify-center items-center relative md:h-[108px]">
        <div
          className="no-repeat absolute w-full h-full"
          style={{ backgroundImage: 'url(/images/ornamentBg.png)' }}
        />
        <div
          style={{
            background:
              'linear-gradient(to right, rgba(1, 17, 32, 0.8), #1E4161, rgba(1, 17, 32, 0.8))',
          }}
          className="absolute w-full h-full "
        />
        <div className="z-10 relative flex items-center justify-center flex-wrap py-[10px]">
          <img src="/images/gerb.png" alt="gerb" width={57} height={57} />
          <div className="ml-[15px] mt-2 md:mt-0">
            <p className="text-[#FFFFFFB2] w-[60%]">
              © 2024 {t('footer.copyright')}
            </p>
            <p className="text-[#FFFFFFB2] w-[80%]">
              Все права на материалы, находящиеся на сайте, охраняются в
              соответствии с законодательством КР.
            </p>
          </div>
          <img
            className="rounded-[10px] order-[-1]"
            src="/images/flag.png"
            alt="flag"
            width={67}
            height={57}
          />
        </div>
      </div>
    </div>
  );
};
