import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Carousel, Empty } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { UsefulResourcesStateSelector } from '@modules/home/domain/store/selector';
import { getUsefulResourcesLinks } from '@modules/home/domain/store/slice';
import { UsefulResourcesCard } from '@modules/home/ui/UsefulResourcesCard';
import { Loading } from '@modules/loading';
import { SectionTitle } from '@modules/sectionTitle';
import { Icon } from '@ui-kit/icon';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const Links: React.FC = () => {
  const dispatch = useAppDispatch();
  const carouselRef = useRef<any>(null);
  const { usefulResourcesLink, totalUsefulResourcesLink, loading } =
    useAppSelector(UsefulResourcesStateSelector);
  const { language } = useAppSelector(NavbarStateSelector);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getUsefulResourcesLinks({ params: { lang: language } }));
  }, [language]);

  const goToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const goToPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <div className="mt-[80px]">
      <div className="rounded-[20px] shadow-lg pt-[32px] pb-[66px] mt-[40px] mb-[80px] p-[10px]">
        <SectionTitle title={t('mainPage.useful-resourses-links')} />
        <Carousel ref={carouselRef} slidesToShow={4} dots={false}>
          {usefulResourcesLink?.map((resource) => (
            <div key={resource.id} className="px-2">
              <UsefulResourcesCard {...resource} />
            </div>
          ))}
        </Carousel>

        <Button
          type="primary"
          onClick={goToNext}
          className="text-primaryBlue shadow-lg bg-white float-right flex items-center justify-center w-[50px] h-[50px] relative bottom-[150px] left-[20px] rounded-full"
        >
          <Icon type="arrowRight" />
        </Button>
        <Button
          type="primary"
          onClick={goToPrev}
          className="text-primaryBlue bg-white float-left flex items-center justify-center w-[50px] h-[50px] relative bottom-[150px] right-[20px] rounded-full"
        >
          <Icon type="arrowLeft" />
        </Button>
      </div>
    </div>
  );
};
