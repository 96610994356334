import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Typography } from 'antd';

const { Paragraph } = Typography;

export const HomepageIntro: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex justify-between items-center bg-[#1E4161] relative">
      <div
        className="h-[470px] flex-1"
        style={{
          backgroundImage:
            'linear-gradient(to left, #1E4161 20%, transparent 50%), url(./images/ornament.png)',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <div
        className="absolute w-full max-w-[1080px] flex flex-col flex-1 justify-center z-10 left-[50%] pl-5"
        style={{
          transform: 'translate(-50%, -20%)',
        }}
      >
        <h2 className="text-[40px] font-bold text-white mb-[16px]">
          {t('headerIntro.title')}
        </h2>
        <Paragraph
          ellipsis={{
            rows: 3,
            expandable: false,
          }}
          style={{
            marginBottom: '5px',
          }}
          className="text-white text-[16px] leading-[22px] w-[50%]"
        >
          {t('headerIntro.description')}
        </Paragraph>
        <Link className="text-[#14B9FF]" to="/about-ESKMP">
          {t('headerIntro.learn-more')}
        </Link>
        <div className="mt-10">
          <button
            type="button"
            className="text-red-500 bg-white font-bold py-[16px] px-[40px] rounded-xl"
            onClick={() => navigate('/incident-report')}
          >
            {t('headerIntro.report-btn')}
          </button>
        </div>
      </div>
      <div
        className="h-[470px] flex-1"
        style={{
          clipPath: 'polygon(16% 0, 100% 0, 100% 100%, 0% 100%)',
          backgroundImage: 'url(./images/homepageBg.png)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      />
    </div>
  );
};
