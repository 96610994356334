import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Pagination, Spin, Typography } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { AllNewsCard } from '@modules/home/ui/AllNewsCard';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NewsStateSelector } from '../domain/store/selector';
import { getNewsByDate } from '../domain/store/slice';

export const NewsInfo: React.FC = () => {
  const [page, setPage] = useState(1);

  const dispatch = useAppDispatch();
  const { newsByDate, loading, totalNewsByDate, selectedParams } =
    useAppSelector(NewsStateSelector);

  const { language } = useAppSelector(NavbarStateSelector);

  useEffect(() => {
    dispatch(
      getNewsByDate({
        perPage: 10,
        lang: language,
        page,
        ...selectedParams,
      }),
    );
  }, [language, page, selectedParams]);

  return (
    <div className="min-h-[666px]">
      {loading ? <Spin spinning={loading} fullscreen /> : null}
      {newsByDate.length ? (
        <div className="flex flex-col h-full">
          <div>
            {newsByDate.map((info) => (
              <div key={info.id} className="mt-[5px]">
                <AllNewsCard {...info} />
              </div>
            ))}
          </div>
          <Pagination
            className="flex justify-end mt-4 my-5"
            pageSize={10}
            current={page}
            onChange={setPage}
            total={totalNewsByDate}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <Typography className="text-[2rem]">Новости отсутствуют</Typography>
        </div>
      )}
    </div>
  );
};
