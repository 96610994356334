import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Empty, Menu, Spin } from 'antd';

import { AppstoreOutlined } from '@ant-design/icons';
import { useWindowDimensions } from '@hooks/useWindowDimesions/useWindowDimesions';
import { Container } from '@modules/container';
import { DisastersSelectedItem } from '@modules/disasters';
import { DisastersStateSelector } from '@modules/disasters/domain/store/selector';
import {
  getAllCatalogs,
  getCatalog,
} from '@modules/disasters/domain/store/slice';
import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { SectionTitle } from '@modules/sectionTitle';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export const DisastersPage: React.FC = () => {
  const { catalogs, loading, currentItem } = useAppSelector(
    DisastersStateSelector,
  );
  const navigate = useNavigate();

  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // const { collapseItems, expandIconFunction } = useCollapseItems();
  const { language } = useAppSelector(NavbarStateSelector);

  const { width } = useWindowDimensions();

  const catalogsMutation = useMemo(() => {
    return catalogs.map((item) => {
      return {
        key: item.id,
        label: item.name,
        icon: <AppstoreOutlined />,
        children: item.catalog.map((itemC) => {
          return {
            key: itemC.id,
            label: itemC.name,
          };
        }),
      };
    });
  }, [catalogs]);

  useEffect(() => {
    dispatch(getAllCatalogs({ params: { lang: language } }));
  }, [language]);

  useEffect(() => {
    if (id) dispatch(getCatalog({ id }));
  }, [id]);

  return (
    <Container>
      <SectionTitle title={t('naturalDisaster.title')} />
      {catalogs.length !== 0 ? (
        <Menu
          mode={width >= 600 ? 'horizontal' : 'inline'}
          items={catalogsMutation}
          onClick={(e) => navigate(`/natural-disasters/${e.key}`)}
          style={{ margin: '25px 0' }}
        />
      ) : (
        <div className="p-6 flex-1">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
      <div className="my-[24px] flex justify-between gap-[50px] lg:mb-[165px] flex-wrap min-h-[500px]">
        {/* {catalogs.length ? ( */}
        {/*  <div className="w-full md:w-[320px]"> */}
        {/*    <Collapse */}
        {/*      expandIcon={expandIconFunction} */}
        {/*      items={collapseItems} */}
        {/*      ghost */}
        {/*      expandIconPosition="end" */}
        {/*      className="forecastCollapse mb-4" */}
        {/*    /> */}
        {/*  </div> */}
        {/* ) : ( */}
        {/*  <div className="p-6 flex-1"> */}
        {/*    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}
        {/*  </div> */}
        {/* )} */}
        {currentItem ? (
          <div className="disaster flex-1">
            {!loading ? (
              <DisastersSelectedItem />
            ) : (
              <div>
                <Spin spinning={loading} />
              </div>
            )}
          </div>
        ) : (
          <div className="p-6 flex-1">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Выберите вид стихийного бедствия"
            />
          </div>
        )}
      </div>
    </Container>
  );
};
