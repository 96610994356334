import { useParams } from '@hooks/useParams/useParams';
import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import {
  IForecastMaterialsState,
  IMaterialGroup,
  IMaterialInfo,
} from '../interfaces/forecastMaterialsState';
import { ForecastMaterialsApi } from './forecastMaterialsApi';
import { ForecastMaterialsSliceConstants } from './forecastMaterialsSliceConstants';

const initialState: IForecastMaterialsState = {
  forecastMaterials: null,
  forecastMaterialInfo: null,
  loading: false,
  error: null,
  currentMaterialItem: null,
};

export const getForecastMaterials = createAsyncThunk(
  ForecastMaterialsSliceConstants.GetForecastMaterials,
  async function (
    { params }: { params: Record<string, any> },
    { rejectWithValue },
  ) {
    try {
      const urlParams = useParams(params);
      const { data } = await axiosInstance.get<Awaited<IMaterialGroup[]>>(
        `${ForecastMaterialsApi.material}${urlParams}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getForecastMaterialInfo = createAsyncThunk(
  ForecastMaterialsSliceConstants.GetForecastMaterialInfo,
  async function (
    { params }: { params: Record<string, any> },
    { rejectWithValue },
  ) {
    try {
      const urlParams = useParams(params);
      const { data } = await axiosInstance.get(
        `${ForecastMaterialsApi.materialInfo}${urlParams}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const ForecastMaterialsSlice = createSlice({
  name: 'ForecastMaterialsSlice',
  initialState,
  reducers: {
    changeMaterialItem: (state, { payload }) => {
      state.currentMaterialItem = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getForecastMaterials.fulfilled,
        (
          state: IForecastMaterialsState,
          { payload }: { payload: IMaterialGroup[] },
        ): IForecastMaterialsState => {
          return {
            ...state,
            forecastMaterials: payload.map((item) => ({
              ...item,
              materials: item.materials.filter((f) => !isNull(f)),
            })),
            loading: false,
            error: null,
          };
        },
      )
      .addCase(
        getForecastMaterials.pending,
        (state: IForecastMaterialsState): IForecastMaterialsState => {
          return {
            ...state,
            loading: true,
          };
        },
      )
      .addCase(
        getForecastMaterials.rejected,
        (
          state: IForecastMaterialsState,
          { payload }: any,
        ): IForecastMaterialsState => {
          return {
            ...state,
            loading: false,
            error: payload,
          };
        },
      );
    builder
      .addCase(
        getForecastMaterialInfo.fulfilled,
        (
          state: IForecastMaterialsState,
          { payload }: { payload: IMaterialInfo },
        ): IForecastMaterialsState => {
          return {
            ...state,
            forecastMaterialInfo: payload,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(
        getForecastMaterialInfo.pending,
        (state: IForecastMaterialsState): IForecastMaterialsState => {
          return {
            ...state,
            loading: true,
          };
        },
      )
      .addCase(
        getForecastMaterialInfo.rejected,
        (
          state: IForecastMaterialsState,
          { payload }: any,
        ): IForecastMaterialsState => {
          return {
            ...state,
            loading: false,
            error: payload,
          };
        },
      );
  },
});

export const { changeMaterialItem } = ForecastMaterialsSlice.actions;
export default ForecastMaterialsSlice.reducer;
