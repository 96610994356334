import React from 'react';

interface IHomePageProps {
  children: React.ReactNode;
}

export const Container: React.FC<IHomePageProps> = ({ children }) => {
  return (
    <div className="mt-10 w-full max-w-[1400px] m-auto flex flex-col flex-1 px-2">
      {children}
    </div>
  );
};
