import { Container } from '@modules/container';
import { AllInformationHeader } from '@modules/information/ui/AllInformationHeader';
import { CurrentInformation } from '@modules/information/ui/CurrentInformation';

export const CurrentInformationPage: React.FC = () => {
  return (
    <Container>
      <AllInformationHeader />
      <CurrentInformation />
    </Container>
  );
};
