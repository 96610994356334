import { CSSProperties } from 'react';

import AntIcon from '@ant-design/icons';

import { icons } from './icon.helper';
import { IIconType } from './interface';

interface IIconProps {
  type: IIconType;
  style?: CSSProperties;
  className?: string;
  title?: string;
}

export const Icon = ({
  type,
  className = '',
  style = {},
  title = '',
}: IIconProps) => {
  return (
    <AntIcon
      component={icons[type]}
      className={className}
      style={style}
      title={title}
    />
  );
};
