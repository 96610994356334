import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useMatches, useNavigate } from 'react-router-dom';

import { Breadcrumb } from 'antd';

import { menu } from '@config/router/menu';
import { Footer } from '@modules/footer';
import { Header } from '@modules/header/';
import { Icon } from '@ui-kit/icon';

export const Layout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentActive = location.pathname;
  const matches = useMatches();
  const isHomePage = true; // currentActive === '/';
  const { t } = useTranslation();

  return (
    <>
      <Header />
      {!isHomePage && (
        <Breadcrumb
          className="w-full max-w-[1080px] m-auto mb-[20px] mt-[46px]"
          separator={<Icon type="arrowRight" />}
        >
          {matches.map((item) => {
            const breadcrumbItems = item.pathname
              .split('/')
              .filter(
                (f) =>
                  f.length &&
                  menu.find((menuItem) => menuItem.path.includes(f)),
              );

            if (item.pathname === '/') {
              return (
                <Breadcrumb.Item
                  key={item.pathname}
                  className=" cursor-pointer p-[3px] hover:bg-gray-300 transition-all rounded"
                  onClick={() => navigate('/')}
                >
                  {t('breadcrumbs.main-page')}
                </Breadcrumb.Item>
              );
            }
            return breadcrumbItems.map((crumb) => {
              const currentCrumb = menu.find((menuItem) =>
                menuItem.path.includes(crumb),
              );

              return (
                <Breadcrumb.Item
                  key={crumb}
                  onClick={() => navigate(currentCrumb?.path ?? '')}
                  className="cursor-pointer"
                >
                  {t([`breadcrumbs.${currentCrumb?.path.substring(1)}`])}
                </Breadcrumb.Item>
              );
            });
          })}
        </Breadcrumb>
      )}
      <Outlet />
      <Footer />
    </>
  );
};
