export const dashboardsList = [
  {
    name: 'Көк-Мойнок',
    dashboard:
      'https://dashboard.isoc.kg/public-dashboards/870d37a7a6524e4aaca5cc7645cf53db',
  },
  {
    name: 'Кыз-Күйөө',
    dashboard:
      'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=3&from=1724115591062&to=1724137191063&kiosk',
  },
  {
    name: 'Байтик',
    dashboard:
      'https://dashboard.isoc.kg/public-dashboards/96cb9c7f9fe94d53bae163465dc98aca',
  },
  {
    name: 'Татыр',
    dashboard:
      'https://dashboard.isoc.kg/public-dashboards/f91fa0be536d4867a1d7f0480313c019',
  },
];

// export const dashboards = [
//   {
//     url: 'http://dashboard.isoc.kg/d/rum1F-uVz/meteodashbord?orgId=3&refresh=1d&from=1714905151739&to=1714926751739&viewPanel=2&kiosk',
//     label: 'Относительная влажность воздуха',
//   },
//   {
//     url: 'http://dashboard.isoc.kg/d/rum1F-uVz/meteodashbord?orgId=3&refresh=1d&from=1714905350673&to=1714926950673&viewPanel=5&kiosk',
//     label: 'Температура воздуха',
//   },
//   {
//     url: 'http://dashboard.isoc.kg/d/rum1F-uVz/meteodashbord?orgId=3&refresh=1d&from=1714905363550&to=1714926963551&viewPanel=4&kiosk',
//     label: 'Солнечная радиация',
//   },
//   {
//     url: 'https://dashboard.isoc.kg/d/rum1F-uVz/obschij-meteodashbord?orgId=4&refresh=1d&from=1723974150673&to=1724060550673&viewPanel=2',
//     label: 'Атмосферное давление',
//   },
//   {
//     url: 'http://dashboard.isoc.kg/d/rum1F-uVz/meteodashbord?orgId=3&refresh=1d&from=1714905383737&to=1714926983738&viewPanel=6&kiosk',
//     label: 'Осадки',
//   },
//   {
//     url: 'http://dashboard.isoc.kg/d/rum1F-uVz/meteodashbord?orgId=3&refresh=1d&from=1714905393621&to=1714926993621&viewPanel=7&kiosk',
//     label: 'Скорость ветра',
//   },
//   {
//     url: 'http://dashboard.isoc.kg/d/rum1F-uVz/meteodashbord?orgId=3&refresh=1d&from=1714905402891&to=1714927002891&viewPanel=8&kiosk',
//     label: 'Направление ветра',
//   },
//   {
//     url: 'http://dashboard.isoc.kg/d-solo/rum1F-uVz/meteodashbord?orgId=3&refresh=1d&from=1714905963363&to=1714927563363&panelId=10&kiosk',
//     label: 'Станции',
//   },
// ];

// export const dashboardsDistrict = [
//   {
//     district: 'Жердеги сенсорлор',
//     dashboards: [
//       {
//         url: 'https://dashboard.isoc.kg/d/VhaDp-X4z3/zherdegi-sensorlor?orgId=4&from=1723458819216&to=1724063619217&viewPanel=2',
//         label: 'Татыр - топурактын температурасы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/VhaDp-X4z3/zherdegi-sensorlor?orgId=4&from=1723458838679&to=1724063638679&viewPanel=3',
//         label: 'Татыр - топурактын нымдуулулу',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/VhaDp-X4z3/zherdegi-sensorlor?orgId=4&from=1723458859265&to=1724063659266&viewPanel=4',
//         label: 'Боом - топурактын температурасы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/VhaDp-X4z3/zherdegi-sensorlor?orgId=4&from=1723458880132&to=1724063680132&viewPanel=5',
//         label: 'Боом - топурактын нымдуулулу жана температурасы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/VhaDp-X4z3/zherdegi-sensorlor?orgId=4&from=1723458915990&to=1724063715990&viewPanel=6',
//         label: 'Ала-Арча гидропост',
//       },
//     ],
//   },
//   {
//     district: 'Татыр',
//     dashboards: [
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724039902996&to=1724061502996&viewPanel=2',
//         label: 'Салыштырмалуу абанын нымдуулугу',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724039913776&to=1724061513776&viewPanel=5',
//         label: 'Абанын температурасы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724039971197&to=1724061571197&viewPanel=4',
//         label: 'Күн нурунун тийиши',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724039979591&to=1724061579591&viewPanel=3',
//         label: 'Аба басымы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724039989997&to=1724061589997&viewPanel=6',
//         label: 'Жаан-чачындын көлөмү',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724040001593&to=1724061601593&viewPanel=7',
//         label: 'Шамал ылдамдыгы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724040013916&to=1724061613917&viewPanel=8',
//         label: 'Шамалдын үйлөгөн тарабы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz23/tatyr?orgId=4&from=1724040039758&to=1724061639758&viewPanel=10',
//         label: 'Станция Татыр',
//       },
//     ],
//   },
//   {
//     district: 'Көк-Мойнок',
//     dashboards: [
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039636970&to=1724061236970&viewPanel=2',
//         label: 'Салыштырмалуу абанын нымдуулугу',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039650316&to=1724061250317&viewPanel=5',
//         label: 'Абанын температурасы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039664368&to=1724061264368&viewPanel=4',
//         label: 'Күн нурунун тийиши',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039678854&to=1724061278854&viewPanel=3',
//         label: 'Аба басымы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039691140&to=1724061291140&viewPanel=6',
//         label: 'Жаан-чачындын көлөмү',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039711655&to=1724061311655&viewPanel=7',
//         label: 'Шамал ылдамдыгы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039734712&to=1724061334712&viewPanel=8',
//         label: 'Шамалдын үйлөгөн тарабы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz24/kd3a9-k-mojnok?orgId=4&from=1724039745910&to=1724061345910&viewPanel=10',
//         label: 'Станциялар',
//       },
//     ],
//   },
//   {
//     district: 'Кыз-Күйөө',
//     dashboards: [
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039379399&to=1724060979399&viewPanel=2',
//         label: 'Салыштырмалуу абанын нымдуулугу',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039400758&to=1724061000758&viewPanel=5',
//         label: 'Абанын температурасы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039423514&to=1724061023514&viewPanel=4',
//         label: 'Күн нурунун тийиши',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039445161&to=1724061045161&viewPanel=3',
//         label: 'Аба басымы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039470405&to=1724061070405&viewPanel=6',
//         label: 'Жаан-чачындын көлөмү',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039492213&to=1724061092213&viewPanel=7',
//         label: 'Шамал ылдамдыгы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039514713&to=1724061114713&viewPanel=8',
//         label: 'Шамалдын үйлөгөн тарабы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz2ssd/kyz-kd2af-jd3a9-d3a9?orgId=4&from=1724039527049&to=1724061127049&viewPanel=10',
//         label: 'Станциялар',
//       },
//     ],
//   },
//   {
//     district: 'Байтик',
//     dashboards: [
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974387240&to=1724060787240&viewPanel=2',
//         label: 'Салыштырмалуу абанын нымдуулугу',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974403528&to=1724060803528&viewPanel=5',
//         label: 'Абанын температурасы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974414053&to=1724060814053&viewPanel=4',
//         label: 'Күн нурунун тийиши',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974437763&to=1724060837763&viewPanel=3',
//         label: 'Аба басымы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974453168&to=1724060853168&viewPanel=6',
//         label: 'Жаан-чачындын көлөмү',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974469667&to=1724060869667&viewPanel=7',
//         label: 'Шамал ылдамдыгы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974486613&to=1724060886614&viewPanel=8',
//         label: 'Шамалдын үйлөгөн тарабы',
//       },
//       {
//         url: 'https://dashboard.isoc.kg/d/rum1F-uVz12/bajtik?orgId=4&from=1723974496339&to=1724060896339&viewPanel=10',
//         label: 'Станциялар',
//       },
//     ],
//   },
// ];
