import { useParams } from '@hooks/useParams/useParams';
import { axiosInstance } from '@libs/http';
import {
  Catalog,
  IDisastersGroup,
  IDisastersState,
} from '@modules/disasters/domain/interfaces/state';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { Api } from './api';
import { SliceConstants } from './sliceConstants';

const initialState: IDisastersState = {
  catalogs: [],
  subCatalogs: [],
  loading: false,
  currentItem: null,
};

export const getAllCatalogs = createAsyncThunk(
  SliceConstants.GetAllCatalogs,
  async ({ params }: { params: Record<string, any> }, { rejectWithValue }) => {
    const urlParams = useParams(params);
    try {
      const { data } = await axiosInstance.get<Awaited<IDisastersGroup[]>>(
        `${Api.catalogs}${urlParams}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllSubCatalogs = createAsyncThunk(
  SliceConstants.GetAllSubCatalogs,
  async (
    { lang, canDelete }: { lang: string; canDelete?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axiosInstance.get<Awaited<Catalog[]>>(
        `${Api.subCatalogs}?lang=${lang}&canDelete=${canDelete}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getCatalog = createAsyncThunk(
  SliceConstants.GetCatalog,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get<Awaited<Catalog>>(
        `${Api.catalogs}/${id}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const DisastersSlice = createSlice({
  name: 'DisastersSlice',
  initialState,
  reducers: {
    changeCurrentItem: (state, { payload }) => {
      state.currentItem = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllCatalogs.fulfilled,
        (state: IDisastersState, { payload }): IDisastersState => {
          return {
            ...state,
            catalogs: payload,
            loading: false,
          };
        },
      )
      .addCase(
        getAllCatalogs.pending,
        (state: IDisastersState): IDisastersState => {
          return {
            ...state,
            loading: true,
          };
        },
      )
      .addCase(
        getAllCatalogs.rejected,
        (state: IDisastersState, { payload }: any): IDisastersState => {
          return {
            ...state,
            loading: false,
            catalogs: [],
          };
        },
      );
    builder
      .addCase(
        getCatalog.fulfilled,
        (state: IDisastersState, { payload }): IDisastersState => {
          return {
            ...state,
            currentItem: payload,
            loading: false,
          };
        },
      )
      .addCase(
        getCatalog.pending,
        (state: IDisastersState): IDisastersState => {
          return {
            ...state,
            loading: true,
          };
        },
      );
    builder.addCase(
      getAllSubCatalogs.fulfilled,
      (state: IDisastersState, { payload }): IDisastersState => {
        return {
          ...state,
          subCatalogs: payload,
          loading: false,
        };
      },
    );
  },
});

export default DisastersSlice.reducer;
export const { changeCurrentItem } = DisastersSlice.actions;
