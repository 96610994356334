import { Col, Collapse, Row, Typography } from 'antd';

import { DisastersStateSelector } from '@modules/disasters/domain/store/selector';
import { SectionTitle } from '@modules/sectionTitle';

import { useAppSelector } from '../../../store/hooks';

export const DisastersSelectedItem: React.FC = () => {
  const { currentItem } = useAppSelector(DisastersStateSelector);

  return (
    <div className="flex-1">
      <SectionTitle
        title={currentItem?.name ?? ''}
        fontSize={1.5}
        fontWeight={400}
      />
      {currentItem?.description && (
        <div
          className="mt-10 dangerouslyStyles"
          dangerouslySetInnerHTML={{ __html: currentItem?.description ?? '' }}
        />
      )}

      <Row gutter={[15, 10]}>
        {currentItem?.iframe ? (
          <>
            <Col span={24}>
              <Typography>{currentItem?.title}</Typography>
            </Col>
            <Col span={24}>
              <iframe
                src={currentItem?.iframe}
                title={currentItem?.title}
                width="100%"
                height="600px"
                frameBorder="0"
                allowFullScreen
              />
            </Col>
          </>
        ) : null}
        {currentItem?.iframe2 ? (
          <>
            <Col span={24}>
              <Typography>{currentItem?.title2}</Typography>
            </Col>
            <Col span={24}>
              <iframe
                src={currentItem?.iframe2}
                title={currentItem?.title2}
                width="100%"
                height="600px"
                frameBorder="0"
                allowFullScreen
              />
            </Col>
          </>
        ) : null}

        {currentItem?.iframe3 ? (
          <>
            <Col span={24}>
              <Typography>{currentItem?.title3}</Typography>
            </Col>
            <Col span={24}>
              <iframe
                src={currentItem?.iframe3}
                title={currentItem?.title3}
                width="100%"
                height="600px"
                frameBorder="0"
                allowFullScreen
              />
            </Col>
          </>
        ) : null}
        {currentItem?.iframe4 ? (
          <>
            <Col span={24}>
              <Typography>{currentItem?.title4}</Typography>
            </Col>
            <Col span={24}>
              <iframe
                src={currentItem?.iframe4}
                title={currentItem?.title4}
                width="100%"
                height="600px"
                frameBorder="0"
                allowFullScreen
              />
            </Col>
          </>
        ) : null}
        {currentItem?.iframe5 ? (
          <>
            <Col span={24}>
              <Typography>{currentItem?.title5}</Typography>
            </Col>
            <Col span={24}>
              <iframe
                src={currentItem?.iframe5}
                title={currentItem?.title5}
                width="100%"
                height="600px"
                frameBorder="0"
                allowFullScreen
              />
            </Col>
          </>
        ) : null}
      </Row>
      {currentItem?.content && (
        <Collapse className="mt-10">
          <Collapse.Panel header="Описание" key="1">
            <div
              className="mt-10 dangerouslyStyles"
              dangerouslySetInnerHTML={{ __html: currentItem?.content }}
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  );
};
