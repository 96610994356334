import { AppRoutes } from './appRoutes';

export const menu = [
  {
    title: 'Главная',
    path: AppRoutes.default,
  },
  {
    title: 'Мониторинг',
    path: AppRoutes.monitoring,
  },
  {
    title: 'Стихийные бедствия',
    path: AppRoutes.naturalDisasters,
  },
  {
    title: 'Прогнозные материалы',
    path: AppRoutes.forecastMaterials,
  },
  {
    title: 'О ЕСКМП',
    path: AppRoutes.aboutESKMP,
  },
  {
    title: 'Обратная связь',
    path: AppRoutes.contacts,
  },
  {
    title: 'Штормовое предупреждение',
    path: AppRoutes.allInformation,
  },
  {
    title: 'Сообщить о происшествии',
    path: AppRoutes.incidentReport,
  },
  {
    title: 'Новостная лента',
    path: AppRoutes.news,
  },
];
