import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Col, Flex, Pagination, Typography } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { AllNewsCard } from '@modules/home/ui/AllNewsCard';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NewsStateSelector } from '../domain/store/selector';
import { getAllLatestNews, getAllNews, getNews } from '../domain/store/slice';
import { CurrentNewsInfoItem } from './CurrentNewsInfoItem';

export const CurrentAllNewsPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { language } = useAppSelector(NavbarStateSelector);
  const { latestNewsInfo, loading, totalLatestInfo, currentNews } =
    useAppSelector(NewsStateSelector);

  useEffect(() => {
    if (!id) return;
    dispatch(getNews({ id, lang: language }));
  }, [id]);

  useEffect(() => {
    dispatch(
      getAllLatestNews({
        perPage: 3,
        page,
        lang: language,
      }),
    );
  }, [page, language]);

  return (
    <Flex className="mt-[48px] flex-col md:flex-row" gap={10}>
      <div className="md:w-[65%]">
        <CurrentNewsInfoItem currentInformation={currentNews} />
      </div>
      <div className="md:w-[35%] md:pl-[22px]">
        {latestNewsInfo.length ? (
          <div className="min-h-[600px]">
            {latestNewsInfo.map((info) => (
              <AllNewsCard key={info.id} {...info} photoVisible={false} />
            ))}
            <Button
              onClick={() => navigate('/news')}
              className="bg-[#1E4161] text-white py-4 h-full mb-6"
              block
            >
              Новостная лента
            </Button>
            <Pagination
              pageSize={3}
              current={page}
              className="my-5"
              onChange={setPage}
              total={totalLatestInfo}
            />
          </div>
        ) : (
          <Col className="flex items-center justify-center mb-[150px]">
            <Typography className="text-[2rem]">Новости отсутствуют</Typography>
          </Col>
        )}
      </div>
    </Flex>
  );
};
