import { Component, ReactNode } from 'react';

import { Button, Result } from 'antd';

interface IProps {
  children?: ReactNode;
}

interface IState {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<IProps, IState> {
  //  исправить ошибку еслинта
  // eslint-disable-next-line react/state-in-constructor
  public state: IState = {
    hasError: false,
  };

  public static getDerivedStateFromError(error: Error): IState {
    return { hasError: true, error };
  }

  public render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className="flex flex-grow-1 justify-center items-center">
          <Result
            status="error"
            title={<span className="text-[#eb5757]">Что-то пошло не так</span>}
            subTitle={
              <span className="text-[#eb5757]">{error?.message || ''}</span>
            }
            extra={[
              <Button
                type="link"
                size="large"
                key="try"
                onClick={() => {
                  document.location.reload();
                }}
              >
                Попробовать еще раз
              </Button>,
            ]}
          />
        </div>
      );
    }

    return children;
  }
}
