import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from 'antd';

import { useHomeNavigation } from '@modules/home/services/useHomeNavigation/useHomeNavigation';
import { Icon } from '@ui-kit/icon';

export const NavigationElements: React.FC = () => {
  const { navigationElements } = useHomeNavigation();

  return (
    <div className="flex justify-center items-center gap-[32px] relative bottom-[40px]">
      {navigationElements.map((item) => (
        <Link
          key={item.title}
          to={item.blank ? item.link : `${item.link}`}
          target={item.blank ? '_blank' : '_self'}
          className="w-[100%] md:w-[275px] h-[135px] rounded-[15px] flex items-center justify-center gap-[15px] shadow-lg bg-white"
        >
          <Icon
            type={item.icon}
            style={{ width: '45px', height: '45px' }}
            className="defaultIcons"
          />
          <Typography className="text-[#1E4161] text-[16px]">
            {item.title}
          </Typography>
        </Link>
      ))}
    </div>
  );
};
