import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, DatePicker, Flex, Row, Typography } from 'antd';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NewsStateSelector } from '../domain/store/selector';
import {
  changeAllNews,
  changeCurrentNewsPage,
  changeSelectParamsByDate,
} from '../domain/store/slice';

dayjs.extend(utc);
dayjs.extend(timezone);

export const NewsInfoHeader: React.FC = React.memo(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedParams } = useAppSelector(NewsStateSelector);

  const dispatch = useAppDispatch();

  const dateOnChange = useCallback((_date: any, dateString: any) => {
    dispatch(
      changeSelectParamsByDate({
        created: dateString.length ? dateString : undefined,
      }),
    );
    dispatch(changeCurrentNewsPage(1));

    if (id) {
      navigate('/news');
      dispatch(changeAllNews());
    }
  }, []);

  return (
    <Row>
      <Col flex={5}>
        <Flex gap={32}>
          <Col className="w-[330px]">
            <Typography className="mb-2">Дата :</Typography>
            <DatePicker
              value={
                selectedParams.created
                  ? dayjs.tz(selectedParams.created, 'Asia/Bishkek')
                  : null
              }
              className="w-full"
              onChange={dateOnChange}
            />
          </Col>
        </Flex>
      </Col>
    </Row>
  );
});
