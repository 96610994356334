import { Link } from 'react-router-dom';

export const HeaderLogo: React.FC = () => {
  return (
    <div className="flex gap-[12px] items-center">
      <Link
        to="/"
        className="text-primaryBlue font-bold flex items-center gap-2 text-[1.125rem]"
      >
        <img
          src="/images/headerLogo.png"
          alt="coat of arms"
          className="w-[67px]"
          width={57}
          height={57}
        />
        ЕСКМП
      </Link>
    </div>
  );
};
