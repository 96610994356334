export interface ILayer {
  id: string;
  title: string;
  children: any;
}

export interface MapState {
  currentLayer: L.Map | null;
  currentGeoDate: any;
  layers: ILayer | any[];
  geo: any[];
  onLayer: Record<string, any>;
  cmbLayers: CmbLayers[];
  previewCmbLayers: CmbLayers[];
  mapCounts: MapCounts[];
  region: IRegion[];
  district: IDistrict[];
  countLayer: number;
  loading: boolean;
}

interface MapCounts {
  name: string;
  count: string;
  id: string;
}

export interface CmbLayers {
  title: string;
  district: {
    title: string;
    id: number;
  };
  region: {
    title: string;
    id: number;
  };
  id: string;
  file: string;
  value: string;
}

export interface IDictionary {
  value: string;
  id: number;
}

export interface IRegion {
  label: string;
  value: number;
}

export interface IDistrict {
  label: string;
  value: number;
}

export enum OppStatusEnum {
  draft = 'draft',
  pending = 'pending',
  published = 'published',
  mapPublished = 'mapPublished',
}
