import { Button, Col, Row, Typography } from 'antd';

import { CustomCard } from '@ui-kit/card';

import { CmbLayers } from '../domain/interface/interface';

const URL = process.env.REACT_APP_BASE_URL ?? '';

type MapsItemProps = {
  showInfo: boolean;
  item: CmbLayers;
};

export const MapsItem: React.FC<MapsItemProps> = ({
  item,
  showInfo = false,
}) => {
  const navigateMap = () => {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('geoData', item.value);

    const isUrlSearchParams = urlSearchParams.size
      ? `?${urlSearchParams?.toString()}`
      : '';

    const url = `/maps${isUrlSearchParams}`;
    window.open(url, '_blank');
  };

  return (
    <CustomCard
      hoverable
      style={{
        background: '#f4f6fd',
        border: '1px solid rgba(75, 96, 216, 0.15)',
        borderRadius: 15,
        padding: 8,
        height: '230px',
        width: '100%',
      }}
      onClick={navigateMap}
    >
      <div className="w-[300] h-[200px] mb-[20px]">
        <img
          src={`${URL}/layers/file?path=${item.file}`}
          alt={item.file}
          style={{ maxWidth: '300px', maxHeight: '200px' }}
          className="h-[100%] object-center w-[100%] object-contain"
        />
      </div>
      {showInfo ? <div>{item.title}</div> : null}
    </CustomCard>
  );
};
