import { IContacts } from '@modules/contacts/domain/interfaces/ContactsState';
import { SendMessage } from '@modules/contacts/domain/store/slice';

import { useAppDispatch } from '../../../../store/hooks';

interface IUseSendMessage {
  handleSubmitMessage: (values: IContacts) => Promise<any>;
}

export const useSendMessage = (): IUseSendMessage => {
  const dispatch = useAppDispatch();
  const handleSubmitMessage = async (values: IContacts) => {
    return dispatch(SendMessage(values));
  };
  return {
    handleSubmitMessage,
  };
};
