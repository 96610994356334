// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';

import { Container } from '@modules/container';
import {
  ForecastMaterialInformation,
  ForecastMonitoring,
} from '@modules/forecastMaterials';
import { SectionTitle } from '@modules/sectionTitle';

export const ForecastMaterialsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionTitle title={t('forecasting-materials.title')} />
      <ForecastMaterialInformation />
      <ForecastMonitoring />
    </Container>
  );
};
