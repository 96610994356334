import { useTranslation } from 'react-i18next';

import { useModalSettings } from '@modules/header/services';

export const HeaderHelper: React.FC = () => {
  const { t } = useTranslation();
  const { isThemeColored, handleChangeTheme, fontSize, handleFontSizeChange } =
    useModalSettings();

  return (
    <div>
      <h3 className=" text-[20px] leading-[24px] font-medium text-darkGray">
        {t('header.view')}
      </h3>
      <div className="flex justify-around items-center my-[16px]">
        <button
          type="button"
          className={`flex items-center justify-center flex-col w-[48px] h-[48px] ${
            isThemeColored && 'border-[2px] border-double border-primaryBlue'
          }`}
          onClick={() => handleChangeTheme(true)}
        >
          <span className="theme text-[20px] text-white w-full h-full bg-primaryBlue flex items-center justify-center">
            A
          </span>
        </button>
        <button
          type="button"
          className={`flex items-center justify-center w-[48px] h-[48px] ${
            !isThemeColored && 'border-2 border-double border-borderColor'
          }`}
          onClick={() => handleChangeTheme(false)}
        >
          <span className="theme text-white text-[20px] w-full h-full bg-primaryGray flex items-center justify-center">
            A
          </span>
        </button>
      </div>
      <div className="text-[14px] pb-[16px] text-primaryGray flex justify-around border-b border-solid border-borderColor">
        <span className="ml-[8px]">{t('header.colored')}</span>
        <span>{t('header.monochrome')}</span>
      </div>
      <div className="mt-[24px] flex flex-col justify-center items-center">
        <h3 className="text-[20px] font-medium mb-[8px] text-black">
          {t('header.font-size')}
        </h3>
        <div className="flex items-center mt-[16px]">
          <span className="text-[14px] text-primaryGray mr-[8px]">Aa</span>
          <input
            type="range"
            min="14"
            max="24"
            className="slider w-[120px]"
            id="myRange"
            value={fontSize}
            onChange={handleFontSizeChange}
          />
          <span className="text-[24px] text-primaryGray ml-[8px]">Aa</span>
        </div>
      </div>
    </div>
  );
};
