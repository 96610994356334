import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Col, Empty, Flex, Row } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { ForecastMaterialsStateSelector } from '@modules/forecastMaterials/domain/store/selector';
import { changeMaterialItem } from '@modules/forecastMaterials/domain/store/slice';
import { SectionTitle } from '@modules/sectionTitle';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ForecastMaterials } from './ForecastMaterials';

const URL = process.env.REACT_APP_BASE_URL ?? '';

export const ForecastMonitoring = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { currentMaterialItem } = useAppSelector(
    ForecastMaterialsStateSelector,
  );

  return (
    <>
      <div className="mb-10">
        <SectionTitle
          title={t('forecasting-materials.monitoring-forecast-title')}
        />
      </div>
      <Row>
        <Col xs={24} xl={8}>
          <ForecastMaterials />
        </Col>
        <Col xs={24} xl={16} className="pl-6 h-full">
          {currentMaterialItem ? (
            <Col className="flex flex-col">
              <Flex justify="end" className="mb-4 pt-4">
                <Button
                  onClick={() => dispatch(changeMaterialItem(null))}
                  icon={<CloseOutlined />}
                />
              </Flex>
              <img
                src={`${URL}/material/file?path=${currentMaterialItem.image}`}
                alt="doc preview"
                className="h-[500px] object-contain mb-4"
              />
              <Link
                to={currentMaterialItem.link}
                className="text-black"
                target="_blank"
              >
                <Button className="mb-4" type="default" block>
                  Открыть PDF
                </Button>
              </Link>
            </Col>
          ) : (
            <div className="p-1">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
