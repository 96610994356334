import { LocalStorage, LocalStorageKeyEnum } from '@libs/localStorage';
import axios from 'axios';

const URL = process.env.REACT_APP_BASE_URL ?? '';
const MCHS_URL = process.env.REACT_APP_BASE_MCHS_URL ?? '';

const axiosInstance = axios.create({
  baseURL: URL,
});

const axiosMchsInstance = axios.create({
  baseURL: MCHS_URL,
});

axiosInstance.interceptors.request.use(
  (config: any) => {
    if (typeof window !== 'undefined') {
      const token = LocalStorage.getItem(LocalStorageKeyEnum.AccessToken);
      if (token) {
        return {
          ...config,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosMchsInstance.interceptors.request.use(
  (config: any) => {
    if (typeof window !== 'undefined') {
      const token = LocalStorage.getItem(LocalStorageKeyEnum.AccessToken);
      if (token) {
        return {
          ...config,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export { axiosInstance, axiosMchsInstance };
