import React, { useState } from 'react';

import { Button, Dropdown, MenuProps, Space, Tooltip } from 'antd';

import {
  CloseOutlined,
  DownOutlined,
  EyeOutlined,
  MenuOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useWindowDimensions } from '@hooks/useWindowDimesions/useWindowDimesions';
import { LocalStorage, LocalStorageKeyEnum } from '@libs/localStorage';
import { changeLanguage } from '@modules/header/domain/store/slice';
import { HeaderHelper } from '@modules/header/ui/HeaderHelper';
import { HeaderSettings } from '@modules/header/ui/HeaderSettings';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

import i18n from '../../../i18n';
import { useAppDispatch } from '../../../store/hooks';
import { HeaderLogo } from './HeaderLogo';
import { Navbar } from './Navbar';

const lang = LocalStorage.getItem(LocalStorageKeyEnum.Language) ?? 'ru';

const items: MenuProps['items'] = [
  {
    label: 'RU',
    key: 'ru',
  },
  {
    label: 'EN',
    key: 'en',
  },
  {
    label: 'KG',
    key: 'kg',
  },
];

export const Header: React.FC = () => {
  const [dropdown, setDropdown] = useState<string>(lang);
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const [showMenu, setShowMenu] = useState(false);

  const onClick: MenuClickEventHandler = (e) => {
    setDropdown(e.key);
    LocalStorage.setItem(LocalStorageKeyEnum.Language, e.key);
    dispatch(changeLanguage(e.key));
    i18n.changeLanguage(e.key);
  };

  const dropdownTitle: any = items.find((item) => item?.key === dropdown) ?? '';

  return (
    <>
      <div className="shadow-sm flex items-center justify-end py-2">
        <div className="flex justify-between mr-auto lg:m-auto lg:gap-20 items-center ml-5">
          <HeaderLogo />
          {width <= 1024 ? (
            <Button
              style={{ backgroundColor: 'rgb(30, 65, 97)' }}
              type="primary"
              className="ml-5"
              onClick={() => setShowMenu((prev) => !prev)}
              icon={!showMenu ? <MenuOutlined /> : <CloseOutlined />}
            />
          ) : (
            <Navbar />
          )}
        </div>
        <Space>
          <Tooltip trigger="click" title={<HeaderSettings />} color="white">
            <Button icon={<SettingOutlined />} />
          </Tooltip>
          <Tooltip trigger="click" title={<HeaderHelper />} color="white">
            <Button icon={<EyeOutlined />} />
          </Tooltip>
          <Dropdown
            className="mr-8"
            menu={{ items, onClick }}
            trigger={['click']}
          >
            <Button className="p-0 px-2" onClick={(e) => e.preventDefault()}>
              <Space>
                {dropdownTitle.label}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      </div>
      {showMenu && (
        <div
          style={{
            borderTop: '1px solid #ddd',
            boxShadow: '0px 16px 25px -1px rgba(34, 60, 80, 0.2)',
            backgroundColor: '#fff',
            width: '100%',
            padding: '5px 0',
          }}
        >
          <Navbar />
        </div>
      )}
    </>
  );
};
