import { useState } from 'react';
import { Link } from 'react-router-dom';

import { message, Tooltip, Typography } from 'antd';

import { useNormalizeDate } from '@hooks/useNormalizeDate';
import { useShare } from '@modules/home/services';
import { IInformation } from '@modules/information/domain/interface/InformationState';
import { Icon } from '@ui-kit/icon';

interface IInformationProps extends IInformation {
  shareVisible?: boolean;
  contentVisible?: boolean;
}

const { Paragraph } = Typography;

export const InformationCard: React.FC<IInformationProps> = ({
  created,
  title,
  district,
  content,
  id,
  shareVisible = false,
  contentVisible = true,
  code,
}) => {
  const [open, setOpen] = useState(false);

  const shareArray = useShare({
    url: `${window.location.href}all-information/${code}`,
    title,
    email: { body: content },
    size: 20,
    round: true,
  });

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleCopy = (textToCopy: any) => {
    try {
      navigator.clipboard.writeText(
        `${window.location.href}all-information/${textToCopy}`,
      );
      message.info('URL скопирован');
    } catch (e: any) {
      message.error(e.message);
    }
  };

  return (
    <Link to={`/all-information/${code}`} onClick={(e) => e.stopPropagation()}>
      <div className="mb-[32px] border-b-[1px] border-borderColor border-solid pb-8">
        <div className="bg-[#E8E8EA] text-sm text-[#93979A] mb-[8px] p-[8px]">
          <span>{district}</span>
        </div>
        <div className="w-[100%] mb-[8px]">
          <h2 className="font-700 text-[1.143rem] truncate text-primaryGray">
            {title}
          </h2>
        </div>
        <div>
          {contentVisible ? (
            <Paragraph ellipsis={{ rows: 1 }}>{content}</Paragraph>
          ) : (
            ''
          )}
        </div>
        <div className="flex items-center justify-between mt-[15px]">
          <div className="flex gap-2 items-center  text-[#0000005E] font-semibold">
            <Icon type="calendar" className="w-[16px]" />
            {useNormalizeDate(created)}
          </div>
          {shareVisible ? (
            <div className="flex gap-2 items-center">
              <Tooltip
                open={open}
                title={
                  <div className="flex justify-center items-center gap-2">
                    {shareArray.map((item) => item.button)}
                  </div>
                }
                onOpenChange={handleOpenChange}
                placement="bottom"
                trigger={['click']}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenChange(!open);
                  }}
                >
                  <Icon
                    type="share"
                    className="cursor-pointer w-[26px] defaultIcons"
                  />
                </button>
              </Tooltip>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleCopy(code);
                }}
              >
                <Icon
                  type="copy"
                  className="cursor-pointer w-[26px] defaultIcons"
                />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </Link>
  );
};
