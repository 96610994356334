import { useTranslation } from 'react-i18next';

import { IIconType } from '@ui-kit/icon/interface';

interface INavigationElements {
  icon: IIconType;
  title: string;
  link: string;
  blank?: boolean;
}
export const useHomeNavigation = () => {
  const { t } = useTranslation();

  const navigationElements: INavigationElements[] = [
    {
      icon: 'point',
      title: t('headerCards.map'),
      link: 'disaster-map',
      blank: true,
    },
    {
      icon: 'information',
      title: t('headerCards.information'),
      link: 'monitoring',
    },
    {
      icon: 'news',
      title: t('headerCards.news'),
      link: 'news',
    },
    {
      icon: 'lake',
      title: t('headerCards.lake'),
      link: 'https://dev.mchs.apex-consulting.io/public-portal/map',
      blank: true,
    },
    {
      icon: 'fires',
      title: t('headerCards.fires'),
      link: 'https://geosib.rcpod.ru/PlanetaFireKG/',
      blank: true,
    },
  ];
  return {
    navigationElements,
  };
};
