import { Link } from 'react-router-dom';

import { Typography } from 'antd';

import { IContacts } from '@modules/footer/domain/interfaces/interface';
import { SectionTitle } from '@modules/sectionTitle';

import { IContactsInfo, IFooterInfoItemProps } from './interface';

const InfoSectionsItem = (infoTypeArray: IContacts | IContactsInfo) => {
  return (
    <li className="text-sm text-primaryGray">
      {'code' in infoTypeArray ? (
        <Typography>{`${infoTypeArray.title} : ${infoTypeArray.content}`}</Typography>
      ) : (
        <Link to={infoTypeArray.path}>{infoTypeArray.title}</Link>
      )}
    </li>
  );
};

export const FooterInfoItem: React.FC<IFooterInfoItemProps> = ({
  title,
  infoTypeArray,
}) => {
  return (
    <div>
      <SectionTitle title={title} fontSize={1.375} />
      <ul className="mt-[14px] flex flex-col lg:w-[400px]">
        {infoTypeArray.map((item) => (
          <InfoSectionsItem key={item.title} {...item} />
        ))}
      </ul>
    </div>
  );
};
