import { useParams } from '@hooks/useParams/useParams';
import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  IGetHomeNews,
  IGetProposalInfoNews,
  INewsItem,
  INewsState,
} from '../interface/newsState';
import { NewsApi } from './newsApi';
import { NewsSliceConstants } from './newsSliceConstants';

const initialState: INewsState = {
  currentPage: 1,
  date: dayjs().format('YYYY-MM-DD'),
  currentNewsLoading: false,
  loading: false,
  currentNews: null,
  allNews: [],
  totalNews: 0,
  latestNewsInfo: [],
  totalLatestInfo: 0,
  selectedParams: {},
  newsByDate: [],
  totalNewsByDate: 0,
  error: null,
};

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getNews = createAsyncThunk(
  NewsSliceConstants.GetNews,
  async ({ id, lang }: { id: string; lang: string }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get<Awaited<INewsItem>>(
        `${BASE_URL}/news/${id}?lang=${lang}&code=${id}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllNews = createAsyncThunk(
  NewsSliceConstants.GetAllNews,
  async function (
    params: {
      page?: number;
      perPage?: number;
      lang: string;
    },
    { rejectWithValue },
  ) {
    const urlParams = useParams(params);

    try {
      const { data } = await axiosInstance.get<IGetHomeNews>(
        `${BASE_URL}/news${urlParams}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllLatestNews = createAsyncThunk(
  NewsSliceConstants.GetAllLatestNews,
  async function (
    params: {
      page?: number;
      perPage?: number;
      lang: string;
    },
    { rejectWithValue },
  ) {
    const urlParams = useParams(params);

    try {
      const { data } = await axiosInstance.get<IGetProposalInfoNews>(
        `${BASE_URL}/news/proposal-info${urlParams}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getNewsByDate = createAsyncThunk(
  NewsSliceConstants.GetNewsDate,
  async function (
    params: { page?: number; perPage?: number; created?: string; lang: string },
    { rejectWithValue },
  ) {
    const urlParams = useParams(params);

    try {
      const { data } = await axiosInstance.get<Awaited<IGetHomeNews>>(
        `${BASE_URL}/news${urlParams}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const NewsSlice = createSlice({
  name: 'NewsSlice',
  initialState,
  reducers: {
    changeCurrentNewsPage: (state: INewsState, { payload }) => {
      state.currentPage = payload;
    },
    changeAllNews: (state) => {
      state.currentNews = null;
    },
    changeCurrentByDate: (state: INewsState, { payload }) => {
      state.date = payload;
    },
    changeSelectParamsByDate: (
      state,
      { payload }: PayloadAction<Record<string, any>>,
    ) => {
      state.selectedParams = { ...state.selectedParams, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNews.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          currentNews: payload,
        };
      })
      .addCase(getNews.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getNews.rejected, (state, { payload }: any) => {
        return {
          ...state,
          loading: false,
        };
      });
    builder
      .addCase(getAllNews.fulfilled, (state, { payload }) => {
        return {
          ...state,
          allNews: payload.items,
          totalNews: payload.count,
          loading: false,
          error: null,
        };
      })
      .addCase(getAllNews.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllNews.rejected, (state, { payload }: any) => {
        return {
          ...state,
          loading: false,
          error: payload,
        };
      });

    builder
      .addCase(getAllLatestNews.fulfilled, (state, { payload }): any => {
        return {
          ...state,
          latestNewsInfo: payload.item,
          totalLatestInfo: payload.count,
          loading: false,
          error: null,
        };
      })
      .addCase(getAllLatestNews.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllLatestNews.rejected, (state, { payload }: any) => {
        return {
          ...state,
          loading: false,
          error: payload,
        };
      });
    builder
      .addCase(getNewsByDate.fulfilled, (state, { payload }): any => {
        return {
          ...state,
          newsByDate: payload.items,
          totalNewsByDate: payload.count,
          loading: false,
          error: null,
        };
      })
      .addCase(getNewsByDate.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getNewsByDate.rejected, (state, { payload }: any) => {
        return {
          ...state,
          loading: false,
          error: payload,
        };
      });
  },
});

export const {
  changeCurrentNewsPage,
  changeAllNews,
  changeCurrentByDate,
  changeSelectParamsByDate,
} = NewsSlice.actions;

export default NewsSlice.reducer;
