import { useTranslation } from 'react-i18next';

import { navbarAppRoutes } from './interface';

export const useNavbarRoutes = () => {
  const { t } = useTranslation();
  return [
    {
      route: t('header.main'),
      path: navbarAppRoutes.default,
    },
    {
      route: t('header.monitoring'),
      path: navbarAppRoutes.monitoring,
    },
    {
      route: t('header.natural-disasters'),
      path: navbarAppRoutes.naturalDisasters,
    },
    {
      route: t('header.forecasting-materials'),
      path: navbarAppRoutes.forecastMaterials,
    },
    {
      route: t('header.data-platform'),
      path: navbarAppRoutes.cmbData,
      blank: true,
    },
    {
      route: t('header.maps'),
      path: navbarAppRoutes.maps,
    },
    {
      route: t('header.about-dmpchs'),
      path: navbarAppRoutes.aboutESKMP,
    },
  ];
};
