import { useParams } from '@hooks/useParams/useParams';
import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  IGetUsefulResources,
  IHomepageState,
} from '../interfaces/HomepageState';
import { HomepageApi } from './homeApi';
import { HomepageSliceConstants } from './homeSliceConstants';

const initialState: IHomepageState = {
  usefulResources: [],
  usefulResourcesLink: [],
  loading: false,
  error: null,
  totalUsefulResourcesLink: 0,
  processes: [
    { id: 1, name: 'Оползень' },
    { id: 2, name: 'Камнепад' },
    { id: 3, name: 'Подтопление' },
    { id: 4, name: 'Лавина' },
    { id: 5, name: 'Землетрясение' },
  ],
  regions: [],
};

export const getUsefulResources = createAsyncThunk(
  HomepageSliceConstants.GetUsefulResources,
  async function (
    { params }: { params: Record<string, any> },
    { rejectWithValue },
  ) {
    try {
      const urlParams = useParams({ ...params, popular: true });
      const {
        data: { items },
      } = await axiosInstance.get<IGetUsefulResources>(
        `${HomepageApi.usefulResources}${urlParams}`,
      );

      return items;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getRegions = createAsyncThunk(
  HomepageSliceConstants.GetRegions,
  async function (
    { params }: { params: Record<string, any> },
    { rejectWithValue },
  ) {
    try {
      const urlParams = useParams(params);
      const { data } = await axiosInstance.get<string[]>(
        `${HomepageApi.regions}${urlParams}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUsefulResourcesLinks = createAsyncThunk(
  HomepageSliceConstants.GetUsefulResourcesLink,
  async function (
    { params }: { params: Record<string, any> },
    { rejectWithValue },
  ) {
    try {
      const urlParams = useParams(params);
      const { data } = await axiosInstance.get<IGetUsefulResources>(
        `${HomepageApi.usefulResources}${urlParams}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const HomepageSlice = createSlice({
  name: 'HomepageSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getUsefulResources.fulfilled,
        (state: IHomepageState, { payload }): IHomepageState => {
          return {
            ...state,
            usefulResources: payload,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(
        getUsefulResources.pending,
        (state: IHomepageState): IHomepageState => {
          return {
            ...state,
            loading: true,
          };
        },
      )
      .addCase(
        getUsefulResources.rejected,
        (state: IHomepageState, { payload }: any): IHomepageState => {
          return {
            ...state,
            loading: false,
            error: payload,
          };
        },
      );
    builder
      .addCase(
        getUsefulResourcesLinks.fulfilled,
        (state: IHomepageState, { payload }): IHomepageState => {
          return {
            ...state,
            usefulResourcesLink: payload.items.sort((a, b) => {
              const firstLink = a.position ?? 999;
              const secondLink = b.position ?? 999;

              return firstLink - secondLink;
            }),
            totalUsefulResourcesLink: payload.count,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(
        getUsefulResourcesLinks.pending,
        (state: IHomepageState): IHomepageState => {
          return {
            ...state,
            loading: true,
          };
        },
      )
      .addCase(
        getUsefulResourcesLinks.rejected,
        (state: IHomepageState, { payload }: any): IHomepageState => {
          return {
            ...state,
            loading: false,
            error: payload,
          };
        },
      );
    builder.addCase(getRegions.fulfilled, (state, { payload }) => {
      return {
        ...state,
        regions: payload,
      };
    });
  },
});

export default HomepageSlice.reducer;
