export const useSelectIncidentData = () => {
  return [
    { value: 'Землетрясение', label: 'Землетрясение' },
    { value: 'Оползень', label: 'Оползень' },
    { value: 'Сель', label: 'Сель' },
    { value: 'Паводок', label: 'Паводок' },
    {
      value: 'Прорыв плотин высокогорных естественных озер',
      label: 'Прорыв плотин высокогорных естественных озер',
    },
    { value: 'Обвал', label: 'Обвал' },
    { value: 'Камнепад', label: 'Камнепад' },
    {
      value: 'Просадка в лессовых и глинистых грунтах',
      label: 'Просадка в лессовых и глинистых грунтах',
    },
    {
      value: 'Подтопление, повышение уровня грунтовых вод',
      label: 'Подтопление, повышение уровня грунтовых вод',
    },
    { value: 'Геокриологический процесс', label: 'Геокриологический процесс' },
    {
      value: 'Карстово-суффозионный процесс',
      label: 'Карстово-суффозионный процесс',
    },
    { value: 'Гололед', label: 'Гололед' },
    { value: 'Лавина', label: 'Лавина' },
    { value: 'Береговая эрозия', label: 'Береговая эрозия' },
    { value: 'Сильный ветер', label: 'Сильный ветер' },
  ];
};
