export const useMainDangerProcesses = (): {
  photo: string;
  title?: string;
}[] => {
  return [
    {
      title: 'Землетрясение',
      photo: './images/earthquake.png',
    },
    {
      title: 'Камнепад',
      photo: './images/landslide.png',
    },
    {
      title: 'Подтопление, повышение уровня грунтовых вод',
      photo: './images/waterTableRise.png',
    },
    {
      title: 'Паводок',
      photo: './images/leash.png',
    },
    {
      title: 'Лавина',
      photo: './images/damFailure.png',
    },
    {
      title: 'Камнепад',
      photo: './images/rockfall.png',
    },
    {
      title: 'Карстово-суффозионный процесс',
      photo: './images/karstSuffProcess.png',
    },
    {
      title: 'Гололед',
      photo: './images/ice.png',
    },
    {
      title: 'Лавина',
      photo: './images/avalanche.png',
    },
    {
      title: 'Оползень',
      photo: './images/collapse.png',
    },
    {
      title: 'Сильный ветер',
      photo: './images/strongWind.png',
    },
    {
      title: 'Сель',
      photo: './images/waterlogging.png',
    },
  ];
};
