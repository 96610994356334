import { memo, useMemo } from 'react';

import { Button, Col, Row, Typography } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { MapStateSelector } from '../domain/store/selector';
import { removeCurrentGeoData } from '../domain/store/slice';

const flattenArray = (arr: any[]): any[] => {
  const actualItems: any = [];

  arr.forEach((item) => {
    if (Array.isArray(item)) {
      actualItems.push(...flattenArray(item));
    } else {
      actualItems.push(item);
    }
  });

  return actualItems;
};

export const MapGeoDataController = memo(() => {
  const dispatch = useAppDispatch();
  const { currentGeoDate } = useAppSelector(MapStateSelector);

  const hasGeoData = typeof currentGeoDate?.properties !== 'undefined';

  const geoDataProperties = currentGeoDate?.properties;
  const geoDataGeometry = currentGeoDate?.geometry;

  const getCoordiantes = useMemo(() => {
    if (typeof geoDataGeometry?.coordinates === 'undefined') return [];

    const items = flattenArray(geoDataGeometry.coordinates);

    const formatItems = [];

    for (let i = 0; i < items.length; i += 2) {
      formatItems.push({ lat: items[i], lng: items[i + 1] });
    }

    return formatItems;
  }, [geoDataGeometry]);

  const closeController = () => {
    dispatch(removeCurrentGeoData());
  };

  // TODO: Аймак нет, Краткое описание нет

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasGeoData ? (
        <div
          style={{
            padding: 15,
            width: '260px',
            maxWidth: '400px',
            background: 'white',
            margin: '2px',
            zIndex: 10000,
            borderRadius: 15,
            right: 0,
            position: 'absolute',
            overflow: 'auto',
            height: '300px',
            overflowX: 'hidden',
          }}
        >
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'end' }} span={24}>
              <Button
                onClick={closeController}
                type="text"
                icon={<CloseOutlined />}
              />
            </Col>
            <Col>
              <Typography>{geoDataProperties?.type?.title}</Typography>
              <Typography>{geoDataProperties?.code}</Typography>
              <Typography>
                {dayjs(geoDataProperties?.date).format('YYYY-MM-DD')}
              </Typography>
              <Typography>{geoDataProperties?.region?.title}</Typography>
              <Typography>{geoDataProperties?.district?.title}</Typography>
              <Typography>{geoDataProperties?.aimak?.title}</Typography>
              <Typography>{geoDataProperties?.locality?.title}</Typography>
              <Col>
                {getCoordiantes.map((item) => (
                  <Typography key={item.lat}>
                    lat: {item.lng} lng: {item.lat}
                  </Typography>
                ))}
              </Col>
              <Typography>
                Абс. отметка над у.м. - {geoDataProperties?.seaLevelMark}
              </Typography>
              <Typography>Основание - {geoDataProperties?.tracker}</Typography>
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
});
