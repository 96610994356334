import { axiosInstance } from '@libs/http';
import { LocalStorage, LocalStorageKeyEnum } from '@libs/localStorage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { INavbarState } from '../interfaces/NavbarState';
import { NavbarApi } from './navbarApi';
import { NavbarSliceConstants } from './navbarSliceConstants';

const lang = LocalStorage.getItem(LocalStorageKeyEnum.Language) ?? 'ru';

const initialState: INavbarState = {
  NavbarState: null,
  language: lang,
};

export const getNavbarItems = createAsyncThunk(
  NavbarSliceConstants.GetNavbarItems,
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await axiosInstance.get(NavbarApi.NavbarApi);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const NavbarItemsSlice = createSlice({
  name: 'NavbarItemsSlice',
  initialState,
  reducers: {
    setNavbarItems: (state, { payload }) => {
      state.NavbarState = payload;
    },
    changeLanguage: (state, { payload }) => {
      state.language = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNavbarItems.fulfilled, (state, { payload }) => {
      state.NavbarState = payload;
    });
  },
});

export default NavbarItemsSlice.reducer;

export const { changeLanguage } = NavbarItemsSlice.actions;
