import React, { useEffect } from 'react';

import { CollapseProps } from 'antd';

import L from 'leaflet';

import { useAppDispatch } from '../../../store/hooks';
import { changeCurrentLayer } from '../domain/store/slice';
import { MapGeoDataController } from './MapGeoDataController';

interface MapProps {
  collapseItems?: CollapseProps['items'];
  mapHeight?: number | string | undefined;
}

export const Map: React.FC<MapProps> = ({
  mapHeight = undefined,
  collapseItems,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const map = L.map('map', {
      zoomControl: false,
      attributionControl: false,
      minZoom: 7,
    }).setView([41.2044, 74.7661], 7);

    dispatch(changeCurrentLayer(map));

    L.Icon.Default.imagePath = 'https://unpkg.com/leaflet/dist/images/';

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(
      map,
    );

    return () => {
      map.remove();
    };
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      {collapseItems?.length ? (
        <div
          style={{
            padding: 15,
            width: '280px',
            background: 'white',
            margin: '2px',
            zIndex: 10000,
            borderRadius: 15,
            position: 'absolute',
            overflow: 'auto',
            height: '400px',
            overflowX: 'hidden',
          }}
        >
          {collapseItems.map((item) => item as React.ReactElement)}
        </div>
      ) : null}
      <MapGeoDataController />
      <div id="map" style={{ height: mapHeight || '500px', width: '100%' }} />
    </div>
  );
};
