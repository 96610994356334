import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Carousel } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { UsefulResourcesCard } from '@modules/home/ui/UsefulResourcesCard';
import { SectionTitle } from '@modules/sectionTitle';
import { Icon } from '@ui-kit/icon';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { UsefulResourcesStateSelector } from '../domain/store/selector';
import { getUsefulResources } from '../domain/store/slice';

export const UsefulResources: React.FC = () => {
  const { t } = useTranslation();
  const carouselRef = useRef<any>(null);

  const dispatch = useAppDispatch();
  const { usefulResources } = useAppSelector(UsefulResourcesStateSelector);
  const { language } = useAppSelector(NavbarStateSelector);

  const goToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const goToPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  useEffect(() => {
    dispatch(getUsefulResources({ params: { lang: language } }));
  }, [language]);

  return (
    <div className="mt-[48px]">
      <SectionTitle title={t('mainPage.useful-resourses')} />

      <Carousel ref={carouselRef} slidesToShow={4} dots={false}>
        {usefulResources?.map((resource) => (
          <div key={resource.id} className="px-2">
            <UsefulResourcesCard {...resource} />
          </div>
        ))}
      </Carousel>

      <Button
        type="primary"
        onClick={goToNext}
        className="text-primaryBlue shadow-lg bg-white float-right flex items-center justify-center w-[50px] h-[50px] relative bottom-[150px] left-[20px] rounded-full"
      >
        <Icon type="arrowRight" />
      </Button>
      <Button
        type="primary"
        onClick={goToPrev}
        className="text-primaryBlue bg-white float-left flex items-center justify-center w-[50px] h-[50px] relative bottom-[150px] right-[20px] rounded-full"
      >
        <Icon type="arrowLeft" />
      </Button>
    </div>
  );
};
