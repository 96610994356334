import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Col, DatePicker, Flex, Row, Typography } from 'antd';

import { InformationStateSelector } from '@modules/information/domain/store/selector';
import {
  changeCurrentInformation,
  changeCurrentPage,
  changeSelectParams,
} from '@modules/information/domain/store/slice';
import { useSelectItems } from '@modules/information/services';
import { AntSelect } from '@ui-kit/select';
import dayjs from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const AllInformationHeader: React.FC = React.memo(() => {
  const { regions: selectorRegions } = useSelectItems();
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedParams } = useAppSelector(InformationStateSelector);

  const dispatch = useAppDispatch();

  const selectItems = [
    {
      items: selectorRegions,
      title: 'Регионы',
    },
  ];

  const handleSelect = useCallback((value: string) => {
    const districtValue = value.endsWith('ский') ? `${value} район` : value;

    dispatch(
      changeSelectParams({
        district: districtValue.length ? districtValue : undefined,
      }),
    );
    dispatch(changeCurrentPage(1));

    if (id) {
      navigate('/all-information');
      dispatch(changeCurrentInformation());
    }
  }, []);

  const dateOnChange = useCallback((_date: any, dateString: any) => {
    dispatch(
      changeSelectParams({
        created: dateString.length ? dateString : undefined,
      }),
    );
    dispatch(changeCurrentPage(1));

    if (id) {
      navigate('/all-information');
      dispatch(changeCurrentInformation());
    }
  }, []);

  return (
    <Row>
      <Col flex={5}>
        <Flex gap={32}>
          {selectItems.map((item) => (
            <Col key={item.title} className="w-[340px]">
              <AntSelect
                value={selectedParams.district}
                onChange={handleSelect}
                items={item.items}
                title={item.title}
              />
            </Col>
          ))}
          <Col className="w-[330px]">
            <Typography className="mb-2">Дата :</Typography>
            <DatePicker
              value={
                selectedParams.created ? dayjs(selectedParams.created) : null
              }
              className="w-full"
              onChange={dateOnChange}
            />
          </Col>
        </Flex>
      </Col>
    </Row>
  );
});
