import React, { memo } from 'react';

import { Container } from '@modules/container';
import {
  AllNews,
  DangerProcesses,
  HomepageIntro,
  Information,
  Links,
  NavigationElements,
  UsefulResources,
} from '@modules/home';
import { Maps } from '@modules/Map/ui/Maps';

export const HomePage: React.FC = memo(() => {
  return (
    <>
      <HomepageIntro />
      <Container>
        <NavigationElements />
        <DangerProcesses />
        <Maps />
        <Information />
        <AllNews />
        <Links />
        <UsefulResources />
      </Container>
    </>
  );
});
