import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  IReportIncident,
  IReportIncidentState,
} from '../interfaces/ReportIncidentState';
import { ReportIncidentApi } from './reportIncidentApi';
import { ReportIncidentSliceConstants } from './reportIncidentSliceConstants';

const initialState: IReportIncidentState = {
  incidentMessage: [],
  loading: false,
  error: null,
};

export const convertImageToPath = createAsyncThunk(
  ReportIncidentSliceConstants.ConvertImageToPath,
  async ({ image }: { image: any }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', image);

      const { data } = await axiosInstance.post(
        ReportIncidentApi.ReportIncidentImages,
        formData,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const SendReportIncident = createAsyncThunk(
  ReportIncidentSliceConstants.SendReport,
  async (values: IReportIncident, { rejectWithValue, dispatch }) => {
    try {
      const images = values.images.fileList.map(
        (item: any) => item.originFileObj,
      );

      const responses = [];

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const image in images) {
        // eslint-disable-next-line no-await-in-loop
        const { payload } = await dispatch(
          convertImageToPath({ image: images[image] }),
        );
        responses.push(payload);
      }

      const { data } = await axiosInstance.post<Awaited<IReportIncident>>(
        ReportIncidentApi.ReportIncidentApi,
        { ...values, photo: responses.map((item) => item.id) },
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const ReportIncidentSlice = createSlice({
  name: 'ReportIncidentSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        SendReportIncident.fulfilled,
        (state: IReportIncidentState): IReportIncidentState => {
          return {
            ...state,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(
        SendReportIncident.pending,
        (state: IReportIncidentState): IReportIncidentState => {
          return {
            ...state,
            loading: true,
            error: null,
          };
        },
      )
      .addCase(
        SendReportIncident.rejected,
        (
          state: IReportIncidentState,
          { payload }: any,
        ): IReportIncidentState => {
          return {
            ...state,
            loading: false,
            error: payload,
          };
        },
      );
  },
});

export default ReportIncidentSlice.reducer;
