import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@modules/container';
import { MapLayers } from '@modules/Map/ui/MapLayers';
import { Dashboards, Frame } from '@modules/monitoring';
import { SectionTitle } from '@modules/sectionTitle';

export const MonitoringPage = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionTitle paddingBottom="1rem" title={t('monitoring.title')} />
      <MapLayers mapHeight="700px" />
      <Dashboards />
      <Frame />
    </Container>
  );
};
