export const useSettingsHeader = () => {
  return [
    {
      label: 'Инспектор',
      link: 'https://inspector.cmbbs.okm.kg/',
    },
    {
      label: 'Система управления',
      link: 'https://adminportal.cmbbs.okm.kg/',
    },
  ];
};
