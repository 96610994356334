import React, { useEffect } from 'react';

import { Empty, Typography } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { Loading } from '@modules/loading';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AboutStateSelector } from '../domain/store/selector';
import { getAboutDescription } from '../domain/store/slice';

export const AboutESKMP: React.FC = () => {
  const dispatch = useAppDispatch();
  const { about, loading } = useAppSelector(AboutStateSelector);
  const { language } = useAppSelector(NavbarStateSelector);

  useEffect(() => {
    dispatch(getAboutDescription({ params: { lang: language } }));
  }, [language]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="pb-[50px] leading-7 links">
      <Typography className="text-center mb-4 text-2xl">
        {about?.title}
      </Typography>
      {about?.content ? (
        <div
          dangerouslySetInnerHTML={{
            __html: about?.content ?? '',
          }}
        />
      ) : (
        <div className="p-6">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
};
