import { Dispatch, SetStateAction, useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

interface IUseCalendar {
  currentDate: Dayjs | null | undefined;
  setCurrentDate: Dispatch<SetStateAction<Dayjs | null | undefined>>;
  goToNextMonth: () => void;
  goToPrevMonth: () => void;
}

export const useCalendar = (): IUseCalendar => {
  const [currentDate, setCurrentDate] = useState<Dayjs | null>();

  const onPanelChange = (value: Dayjs, mode: 'month' | 'year') => {
    let newValue: Dayjs;
    if (mode === 'month') {
      if (value.month() >= dayjs().month()) {
        newValue = value.add(1, 'month');
      } else {
        newValue = value.subtract(1, 'month');
      }
    } else if (value.year() >= dayjs().year()) {
      newValue = value.add(1, 'year');
    } else {
      newValue = value.subtract(1, 'year');
    }
  };

  const goToNextMonth = () => {
    let nextMonth: Dayjs;
    if (currentDate) {
      nextMonth = currentDate.add(1, 'month');
    } else {
      setCurrentDate(dayjs());
      nextMonth = dayjs().add(1, 'month');
    }
    setCurrentDate(nextMonth);
    onPanelChange(nextMonth, 'month');
  };

  const goToPrevMonth = () => {
    let prevMonth: Dayjs;
    if (currentDate) {
      prevMonth = currentDate.subtract(1, 'month');
    } else {
      setCurrentDate(dayjs());
      prevMonth = dayjs().subtract(1, 'month');
    }
    setCurrentDate(prevMonth);
    onPanelChange(prevMonth, 'month');
  };

  return {
    currentDate,
    setCurrentDate,
    goToNextMonth,
    goToPrevMonth,
  };
};
