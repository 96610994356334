import React, { useEffect } from 'react';

import { Button, Col, Divider, message, Row, Spin, Typography } from 'antd';

import { MapStateSelector } from '@modules/Map/domain/store/selector';
import { getCmbLayers } from '@modules/Map/domain/store/slice';
import { MapsItem } from '@modules/Map/ui/MapsItem';
import { SectionTitle } from '@modules/sectionTitle';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const Maps = () => {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { previewCmbLayers = [], loading } = useAppSelector(MapStateSelector);

  const navigateMap = () => {
    const url = '/maps/all';
    window.open(url, '_blank');
  };

  useEffect(() => {
    dispatch(getCmbLayers({ page: 1, perPage: 12 }));
  }, []);

  return (
    <>
      {contextHolder}
      <div className="mb-5 flex">
        <SectionTitle title="Карты" />
        {previewCmbLayers.length !== 0 && (
          <Button onClick={navigateMap} className="ml-auto">
            Все карты
          </Button>
        )}
      </div>
      <Row gutter={[20, 80]}>
        {/* TODO: remove  */}

        {!loading ? (
          previewCmbLayers.map((item) => (
            <Col span={6} key={item.id}>
              <MapsItem item={item} showInfo />
            </Col>
          ))
        ) : (
          <Spin />
        )}

        {previewCmbLayers.length === 0 && (
          <div>
            <Divider />
            <Typography className="text-[2rem]">Карты отсутствуют</Typography>
            <Divider />
          </div>
        )}
        <Divider />
      </Row>
    </>
  );
};
