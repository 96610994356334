import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ConfigProvider, theme } from 'antd';
import enUS from 'antd/locale/en_US';
import ruRU from 'antd/locale/ru_RU';

import { router } from '@config/router';
import { ErrorBoundary } from '@modules/errorBoundary/ErrorBoundary';
import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { Loading } from '@modules/loading';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
// eslint-disable-next-line import/no-extraneous-dependencies
import CalendarLocale from 'rc-picker/lib/locale/ru_RU';

import { useAppSelector } from './store/hooks';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(localeData);

const locales: any = {
  ru: {
    ...ruRU,
    Calendar: {
      lang: {
        placeholder: 'Выберите дату',
        shortWeekDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        ...CalendarLocale,
      },
      timePickerLocale: {},
    },
    DatePicker: {
      lang: {
        placeholder: 'Выберите дату',
        shortWeekDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        ...CalendarLocale,
      },
      timePickerLocale: {},
    },
  },
  en: enUS,
};

export const App: React.FC = React.memo(() => {
  const { language } = useAppSelector(NavbarStateSelector);

  useEffect(() => {
    dayjs.locale(language);
  }, []);

  return (
    <ConfigProvider
      locale={locales[language] ?? locales.ru}
      theme={{
        algorithm: theme.defaultAlgorithm,
        components: {
          Calendar: {
            colorPrimary: '#FF616E',
          },
          DatePicker: {
            colorPrimary: '#FF616E',
          },
          Pagination: {
            itemActiveBg: '#1E4161',
            colorPrimary: '#FFFFFF',
          },
        },
      }}
    >
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <RouterProvider router={router} />
        </Suspense>
      </ErrorBoundary>
    </ConfigProvider>
  );
});
