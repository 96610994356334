import { Select, Typography } from 'antd';

import { IAntSelectProps } from '@ui-kit/select/interface';

export const AntSelect: React.FC<IAntSelectProps> = ({
  items,
  onChange,
  title,
  value,
}) => {
  return (
    <div className="flex flex-col">
      <Typography className="mb-[8px]">{title} : </Typography>

      <Select
        className="w-full"
        defaultValue={value ?? items[0]}
        onChange={onChange}
        options={items}
      />
    </div>
  );
};
