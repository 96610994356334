import { useParams } from '@hooks/useParams/useParams';
import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAboutDescription, IAboutState } from '../interfaces/aboutState';
import { AboutApi } from './aboutApi';
import { AboutSliceConstants } from './aboutSliceConstants';

const initialState: IAboutState = {
  about: null,
  loading: false,
  error: null,
};

export const getAboutDescription = createAsyncThunk(
  AboutSliceConstants.GetAboutDescription,
  async function (
    { params }: { params: Record<string, any> },
    { rejectWithValue },
  ) {
    const p = useParams(params);

    try {
      const { data } = await axiosInstance.get<Awaited<IAboutDescription>>(
        `${AboutApi.about}/Company${p}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const AboutSlice = createSlice({
  name: 'AboutDescriptionSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getAboutDescription.fulfilled,
        (
          state: IAboutState,
          { payload }: { payload: IAboutDescription },
        ): IAboutState => {
          return {
            ...state,
            about: payload,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(
        getAboutDescription.pending,
        (state: IAboutState): IAboutState => {
          return {
            ...state,
            loading: true,
          };
        },
      )
      .addCase(
        getAboutDescription.rejected,
        (state: IAboutState, { payload }: any): IAboutState => {
          return {
            ...state,
            loading: false,
            error: payload,
          };
        },
      );
  },
});

export default AboutSlice.reducer;
