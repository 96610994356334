import React, { useEffect } from 'react';

import { Collapse, Empty } from 'antd';

import { ForecastMaterialsStateSelector } from '@modules/forecastMaterials/domain/store/selector';
import { changeMaterialItem } from '@modules/forecastMaterials/domain/store/slice';
import { useCollapseItems } from '@modules/forecastMaterials/services';
import { Loading } from '@modules/loading';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const ForecastMaterials = () => {
  const { loading } = useAppSelector(ForecastMaterialsStateSelector);
  const { collapseItems, expandIconFunction } = useCollapseItems();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(changeMaterialItem(null));
  }, [collapseItems]);

  if (loading) {
    return <Loading />;
  }
  return collapseItems?.length ? (
    <div>
      <Collapse
        expandIcon={expandIconFunction}
        items={collapseItems}
        ghost
        expandIconPosition="end"
        className="forecastCollapse mb-4"
      />
    </div>
  ) : (
    <div className="p-6">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};
