import { Container } from '@modules/container';
import { CurrentAllNewsPage } from '@modules/news/ui/CurrentNewsInfo';
import { NewsInfoHeader } from '@modules/news/ui/NewsInfoHeader';

export const CurrentNewsPage: React.FC = () => {
  return (
    <Container>
      <NewsInfoHeader />
      <CurrentAllNewsPage />
    </Container>
  );
};
