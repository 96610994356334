/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Calendar, Col, Pagination, Row } from 'antd';

import { RedoOutlined } from '@ant-design/icons';
import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { InformationCard } from '@modules/home/ui/InformationCard';
import { InformationStateSelector } from '@modules/information/domain/store/selector';
import {
  changeCurrentDate,
  getHomeInformation,
} from '@modules/information/domain/store/slice';
import { SectionTitle } from '@modules/sectionTitle';
import { Icon } from '@ui-kit/icon';
import dayjs, { Dayjs } from 'dayjs';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useCalendar } from '../services';

export const Information: React.FC = () => {
  const [page, setPage] = useState(1);

  const [showAllNews, setShowAllNews] = useState(false);

  const dispatch = useAppDispatch();
  const { homeInformation, loading, totalHomeInformation } = useAppSelector(
    InformationStateSelector,
  );
  const { currentDate, goToNextMonth, goToPrevMonth, setCurrentDate } =
    useCalendar();
  const { language } = useAppSelector(NavbarStateSelector);
  const { t } = useTranslation();

  const upperCaseDate = (date: Dayjs) => {
    dayjs.locale(language);
    const localeDate = date.locale(language).format('MMMM YYYY');
    return localeDate[0].toUpperCase() + localeDate.substring(1);
  };

  const resetParams = () => {
    const today = dayjs();

    dispatch(
      getHomeInformation({
        perPage: 3,
        lang: language,
        page,
      }),
    );
    setCurrentDate(today);
    setShowAllNews(true);
  };

  useEffect(() => {
    if (showAllNews) {
      dispatch(
        getHomeInformation({
          perPage: 3,
          lang: language,
          page,
        }),
      );

      return;
    }

    dispatch(
      getHomeInformation({
        perPage: 3,
        date: currentDate ? currentDate.format('YYYY-MM-DD') : undefined,
        lang: language,
        page,
      }),
    );

    setShowAllNews(false);
  }, [language, page]);

  const onDateSelect = (date: Dayjs) => {
    dispatch(
      getHomeInformation({
        date: date.format('YYYY-MM-DD'),
        perPage: 3,
        lang: language,
        page,
      }),
    );
    dispatch(
      changeCurrentDate({
        date: date.format('YYYY-MM-DD'),
      }),
    );
    setCurrentDate(date);
  };

  return (
    <div className="w-auto">
      <div className="mb-[32px]">
        <SectionTitle title={t('stormWarning.title')} />
        <Link
          to="/all-information"
          className="text-primaryBlue w-max float-end flex items-center gap-[8px] justify-end border"
        >
          {t('stormWarning.newsLink')}
          <Icon className="w-[14px]" type="right" />
        </Link>
      </div>
      <Row gutter={[30, 0]} align="middle">
        <Col span={12}>
          <div className="bg-[#E8E8EA] rounded-[20px] mx-[80px] py-[40px]  flex items-center justify-center">
            <Calendar
              headerRender={() => (
                <div className="flex justify-between items-center px-4">
                  <h3>{upperCaseDate(currentDate ?? dayjs())}</h3>
                  <div className="flex items-center">
                    <button type="button" onClick={goToPrevMonth}>
                      <Icon className="w-[24px]" type="shortArrowLeft" />
                    </button>
                    <button type="button" onClick={goToNextMonth}>
                      <Icon className="w-[24px]" type="shortArrowRight" />
                    </button>
                    <Button
                      onClick={resetParams}
                      type="text"
                      icon={<RedoOutlined />}
                    />
                  </div>
                </div>
              )}
              value={currentDate ?? dayjs()}
              defaultValue={currentDate ?? dayjs()}
              className="rounded-[20px] shadow-lg max-w-[300px]"
              fullscreen={false}
              onSelect={onDateSelect}
            />
          </div>
        </Col>
        <Col span={12}>
          {!totalHomeInformation && !loading ? (
            <div className="flex items-center justify-center">
              <h3 className="text-[2rem] font-semibold">
                {t('stormWarning.emptyNews')}
              </h3>
            </div>
          ) : (
            <div className="flex flex-col justify-between h-full  min-h-[600px]">
              {homeInformation?.map((info) => (
                <InformationCard
                  key={info.id}
                  {...info}
                  shareVisible
                  contentVisible={false}
                />
              ))}
              {totalHomeInformation ? (
                <Pagination
                  pageSize={3}
                  current={page}
                  onChange={setPage}
                  total={totalHomeInformation}
                />
              ) : null}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
