import { combineReducers } from 'redux';

import AboutSlice from '@modules/aboutESKMP/domain/store/slice';
import ContactsSlice from '@modules/contacts/domain/store/slice';
import DisastersSlice from '@modules/disasters/domain/store/slice';
import FooterSlice from '@modules/footer/domain/store/slice';
import ForecastMaterialSlice from '@modules/forecastMaterials/domain/store/slice';
import NavbarSlice from '@modules/header/domain/store/slice';
import HomepageSlice from '@modules/home/domain/store/slice';
import InformationSlice from '@modules/information/domain/store/slice';
import MapSlice from '@modules/Map/domain/store/slice';
import NewsSlice from '@modules/news/domain/store/slice';
import ReportIncidentSlice from '@modules/reportIncident/domain/store/slice';
import { configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  ForecastMaterialSlice,
  AboutSlice,
  ReportIncidentSlice,
  ContactsSlice,
  HomepageSlice,
  InformationSlice,
  DisastersSlice,
  FooterSlice,
  NavbarSlice,
  MapSlice,
  NewsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});
