import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Carousel, Typography } from 'antd';

import noImage from '@assets/noImage.jpg';
import { useWindowDimensions } from '@hooks/useWindowDimesions/useWindowDimesions';
import { DisastersStateSelector } from '@modules/disasters/domain/store/selector';
import { getAllSubCatalogs } from '@modules/disasters/domain/store/slice';
import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { useMainDangerProcesses } from '@modules/home/services';
import { SectionTitle } from '@modules/sectionTitle';
import { Icon } from '@ui-kit/icon';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const DangerProcesses: React.FC = () => {
  const dispatch = useAppDispatch();
  const mainDangerProcesses = useMainDangerProcesses();
  const carouselRef = useRef<any>(null);
  const { language } = useAppSelector(NavbarStateSelector);
  const { subCatalogs } = useAppSelector(DisastersStateSelector);

  const { width } = useWindowDimensions();

  const slidesToShow = width >= 1080 ? 4 : 2;

  const { t } = useTranslation();

  const goToNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const goToPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };

  useEffect(() => {
    dispatch(getAllSubCatalogs({ lang: language, canDelete: false }));
  }, []);

  // const newOpp = useMemo(() => {
  //   if (subCatalogs.length) {
  //     // const oppLength = subCatalogs.slice(0, mainDangerProcesses.length);
  //     return subCatalogs.map((item, idx) => {
  //       const defaultData = mainDangerProcesses.find(
  //         (el) => el.title === item.name,
  //       ) || { photo: '' };
  //       return { ...item, title: item.name, ...defaultData };
  //     });
  //     // return mainDangerProcesses.map((item, idx) => {
  //     //   return { ...item, id: oppLength[idx]?.id, title: oppLength[idx]?.name };
  //     // });
  //   }
  //
  //   return mainDangerProcesses;
  // }, [subCatalogs]);

  const newOpp2 = useMemo(() => {
    if (subCatalogs.length) {
      // const oppLength = subCatalogs.slice(0, mainDangerProcesses.length);
      return subCatalogs
        .map((item: any, idx) => {
          const defaultData =
            mainDangerProcesses.find((el) => el.title === item.name) || {};
          const data = { ...item, title: item.name };
          if (data?.photo) {
            data.photo = `${process.env.REACT_APP_BASE_URL}/material/file?path=${data.photo}`;
          } else {
            delete data.photo;
          }
          return { ...defaultData, ...data };
        })
        .filter((v) => v?.photo);
      // return mainDangerProcesses.map((item) => {
      //   const defaultData = subCatalogs.find(
      //     (el) => el.name === item.title,
      //   ) || { photo: '' };
      //
      //   return {
      //     ...defaultData,
      //     ...item,
      //     title: item.title,
      //     photo: item.photo,
      //   };
      // });
      // return mainDangerProcesses.map((item, idx) => {
      //   return { ...item, id: oppLength[idx]?.id, title: oppLength[idx]?.name };
      // });
    }

    return mainDangerProcesses;
  }, [subCatalogs]);

  return (
    <div className="w-full">
      <div className="mb-[32px]">
        <SectionTitle title={t('mainPage.major-hazardous-processes')} />
        <Link
          to="/natural-disasters"
          className="text-primaryBlue flex items-center gap-[8px] justify-end"
        >
          Все
          <Icon className="w-[14px]" type="right" />
        </Link>
      </div>
      <Carousel
        ref={carouselRef}
        slidesToShow={slidesToShow}
        dots={false}
        style={{ margin: '0 -15px' }}
      >
        {newOpp2?.map((item: any) => (
          <Link key={item.title} to={`/natural-disasters/${item?.id}`}>
            <div className="w-full relative">
              <img
                src={item.photo ? item.photo : noImage}
                alt={item.title}
                width="100%"
                height="100%"
                className="p-[15px] rounded-[30px] w-full h-[250px]"
              />
              <div className="absolute px-[10px] top-[180px] bottom-[15px] left-[15px] right-[15px] textLayer rounded-b-[15px]">
                <Typography className="text-[18px] ml-10 mt-[20px] text-white">
                  {item.title}
                </Typography>
              </div>
            </div>
          </Link>
        ))}
      </Carousel>

      <Button
        type="primary"
        onClick={goToNext}
        className="text-primaryBlue bg-white float-right flex items-center justify-center w-[50px] h-[50px] relative bottom-[150px] left-[0] rounded-full"
      >
        <Icon type="arrowRight" />
      </Button>
      <Button
        type="primary"
        onClick={goToPrev}
        className="text-primaryBlue bg-white float-left flex items-center justify-center w-[50px] h-[50px] relative bottom-[150px] left-[0] rounded-full"
      >
        <Icon type="arrowLeft" />
      </Button>
    </div>
  );
};
