export const useParams = (params: Record<string, any>) => {
  const urlParams = new URLSearchParams();

  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const paramsKey in params) {
    if (typeof params[paramsKey] === 'undefined') {
      urlParams.delete(paramsKey);
      // eslint-disable-next-line no-continue
      continue;
    }
    urlParams.append(paramsKey, params[paramsKey]);
  }

  const isParams = urlParams.size ? `?${urlParams.toString()}` : '';

  return isParams;
};
