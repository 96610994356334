import { IReportIncident } from '@modules/reportIncident/domain/interfaces/ReportIncidentState';
import { SendReportIncident } from '@modules/reportIncident/domain/store/slice';

import { useAppDispatch } from '../../../../store/hooks';

interface IUseReportIncident {
  handleSubmitReportIncident: (values: IReportIncident) => Promise<any>;
}

export const useReportIncident = (): IUseReportIncident => {
  const dispatch = useAppDispatch();
  const handleSubmitReportIncident = async (values: IReportIncident) => {
    return dispatch(SendReportIncident(values));
  };
  return {
    handleSubmitReportIncident,
  };
};
