import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Col, Flex, Pagination, Typography } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { InformationCard } from '@modules/home/ui/InformationCard';
import { InformationStateSelector } from '@modules/information/domain/store/selector';
import {
  changeCurrentPage,
  getLatestInformation,
  getNews,
} from '@modules/information/domain/store/slice';
import { CurrentNews } from '@modules/information/ui/CurrentNews';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const CurrentInformation: React.FC = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { language } = useAppSelector(NavbarStateSelector);
  const { latestNews, currentInformation, currentPage, totalLatest } =
    useAppSelector(InformationStateSelector);

  useEffect(() => {
    if (!id) return;
    dispatch(getNews({ id, lang: language }));
  }, [id]);

  useEffect(() => {
    dispatch(
      getLatestInformation({
        perPage: 3,
        page: currentPage,
        lang: language,
      }),
    );
  }, [currentPage, language]);

  return (
    <Flex className="mt-[48px] flex-col md:flex-row" gap={10}>
      <div className="md:w-[65%]">
        <CurrentNews currentInformation={currentInformation} />
      </div>
      <div className="md:w-[35%] md:pl-[22px]">
        {latestNews.length ? (
          <div className="min-h-[600px]">
            {latestNews.map((info) => (
              <InformationCard key={info.id} {...info} />
            ))}
            <Button
              onClick={() => navigate('/all-information')}
              className="bg-[#1E4161] text-white py-4 h-full mb-6"
              block
            >
              Штормовое предупреждение
            </Button>
            <Pagination
              pageSize={3}
              current={currentPage}
              className="my-5"
              onChange={(page) => dispatch(changeCurrentPage(page))}
              total={totalLatest}
            />
          </div>
        ) : (
          <Col className="flex items-center justify-center mb-[150px]">
            <Typography className="text-[2rem]">Данных нет</Typography>
          </Col>
        )}
      </div>
    </Flex>
  );
};
