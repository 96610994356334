import { useTranslation } from 'react-i18next';

import { ContactsForm } from '@modules/contacts';
import { Container } from '@modules/container';
import { SectionTitle } from '@modules/sectionTitle';

export const ContactsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <SectionTitle title={t('footer.contacts')} fontWeight={500} />
      <ContactsForm />
    </Container>
  );
};
