import React from 'react';

import { Card, CardProps } from 'antd';
import { CardMetaProps } from 'antd/es/card/Meta';

import styles from './CustomCard.module.scss';

type CustomCardPropsType = CardProps & {
  children: React.ReactNode;
  overlay?: boolean;
  props?: CardProps;
  dashed?: boolean;
  light?: boolean;
  withoutBottomPadding?: boolean;
  withoutHeadBorder?: boolean;
};

export const CustomCard: React.FC<CustomCardPropsType> = ({
  children,
  overlay,
  dashed = false,
  light = false,
  withoutBottomPadding = false, // В случаях когда нижний элемент является формочкой с инпутами
  withoutHeadBorder,
  ...props
}) => {
  const titleClass = props?.title ? styles.title : styles.withoutTitle;
  return (
    <Card
      {...props}
      className={`${styles.card} 
      ${dashed && styles.dashed} 
      ${light && styles.light} 
      ${withoutBottomPadding && styles.withoutBottomPadding}
      ${withoutHeadBorder && styles.withoutHeadBorder}
      ${titleClass}
      `}
    >
      {overlay && <div className={styles.overlay} />}
      {children}
    </Card>
  );
};

export const CustomCardMeta: React.FC<CardMetaProps> = ({ ...props }) => {
  return <Card.Meta className={styles.meta} {...props} />;
};
