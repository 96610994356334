import { useState } from 'react';

import { message } from 'antd';

import { ILocationState } from '@modules/reportIncident/services/useLocation/interface';

export const useLocation = (): {
  userLocation: ILocationState;
  getLocation: () => void;
} => {
  const [userLocation, setUserLocation] = useState<ILocationState>({
    longitude: null,
    latitude: null,
  });

  const locationSuccess = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;

    setUserLocation({ latitude, longitude });
  };

  const locationError = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message.error('Пользователь отклонил запрос на геолокацию.');
        break;
      case error.POSITION_UNAVAILABLE:
        message.error('Информация о местоположении недоступна.');
        break;
      case error.TIMEOUT:
        message.error(
          'Запрос на получение местоположения пользователя завершился по времени',
        );
        break;
      default:
        message.error('Произошла неизвестная ошибка!');
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
    } else {
      message.info('Геолокация не поддерживается');
    }
  };

  return { userLocation, getLocation };
};
