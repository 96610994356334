import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapseProps, Empty, Typography } from 'antd';

import { IMaterialItem } from '@modules/forecastMaterials/domain/interfaces/forecastMaterialsState';
import { ForecastMaterialsStateSelector } from '@modules/forecastMaterials/domain/store/selector';
import {
  changeMaterialItem,
  getForecastMaterials,
} from '@modules/forecastMaterials/domain/store/slice';
import { ExpandIconProps } from '@modules/forecastMaterials/ui/interfaces';
import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { Icon } from '@ui-kit/icon';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export const useCollapseItems = (): {
  collapseItems: CollapseProps['items'];
  expandIconFunction: (value: any) => JSX.Element;
} => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { forecastMaterials, currentMaterialItem } = useAppSelector(
    ForecastMaterialsStateSelector,
  );
  const { language } = useAppSelector(NavbarStateSelector);

  const handleClickMaterial = (material: IMaterialItem) => {
    dispatch(changeMaterialItem(material));
  };

  const expandIconFunction = ({ isActive }: ExpandIconProps) =>
    isActive ? (
      <Icon style={{ width: 14 }} type="collapseUp" />
    ) : (
      <Icon style={{ width: 14 }} type="collapseDown" />
    );

  useEffect(() => {
    dispatch(getForecastMaterials({ params: { lang: language } }));
    dispatch(changeMaterialItem(null));
  }, [language]);

  if (!forecastMaterials) {
    return { collapseItems: [], expandIconFunction };
  }
  const items = forecastMaterials.map((item) => {
    return {
      key: item.id,
      label: (
        <Typography className="text-[20px] text-[#1E4161]">
          {item.title}
        </Typography>
      ),
      children: (
        <ul className="ml-[20px] flex flex-col gap-[16px]">
          {item.materials.length ? (
            item.materials.map((subtitle, index) => (
              <li className="text-base" key={subtitle?.id}>
                <button
                  onClick={(e) => handleClickMaterial(subtitle)}
                  type="button"
                  className={`text-primaryBlue text-left ${
                    currentMaterialItem?.id === subtitle.id
                      ? 'activeMaterial'
                      : ''
                  }`}
                >
                  {subtitle.name}
                </button>
              </li>
            ))
          ) : (
            <div className="p-1">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </ul>
      ),
    };
  });
  return { collapseItems: items, expandIconFunction };
};
