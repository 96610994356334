import { createBrowserRouter } from 'react-router-dom';

import { CurrentNews } from '@modules/information/ui/CurrentNews';
import { Layout } from '@modules/layouts';
import { AllMaps } from '@modules/Map/ui/AllMaps';
import { MapLayers } from '@modules/Map/ui/MapLayers';
import { MapLayout } from '@modules/Map/ui/MapLayout';
import { AboutPage } from '@modules/scenarios-constructor/ui/aboutPage/AboutPage';
import { AllInformationPage } from '@modules/scenarios-constructor/ui/allInformationPage/AllInformationPage';
import { ContactsPage } from '@modules/scenarios-constructor/ui/contactsPage/ContactsPage';
import { CurrentInformationPage } from '@modules/scenarios-constructor/ui/CurrentInformationPage/CurrentInformationPage';
import { CurrentNewsPage } from '@modules/scenarios-constructor/ui/CurrentNewsPage/CurrentNewsPage';
import { DisastersPage } from '@modules/scenarios-constructor/ui/disastersPage/DisastersPage';
import { ForecastMaterialsPage } from '@modules/scenarios-constructor/ui/forecastMaterialsPage/ForecastMaterialsPage';
import { HomePage } from '@modules/scenarios-constructor/ui/homePage/HomePage';
import { IncidentReportPage } from '@modules/scenarios-constructor/ui/incidentReportPage/IncidentReportPage';
import { MonitoringPage } from '@modules/scenarios-constructor/ui/monitoringPage/MonitoringPage';
import { NewsPage } from '@modules/scenarios-constructor/ui/newsPage/NewsPage';
import { NotFoundPage } from '@modules/scenarios-constructor/ui/notFoundPage/NotFoundPage';

import { AppRoutes } from './appRoutes';

export const router = createBrowserRouter([
  {
    path: AppRoutes.default,
    element: <Layout />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: AppRoutes.default,
        element: <HomePage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.naturalDisasters,
        element: <DisastersPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: `${AppRoutes.naturalDisasters}/:id`,
        element: <DisastersPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.monitoring,
        element: <MonitoringPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.allInformation,
        element: <AllInformationPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: `${AppRoutes.allInformation}/:id`,
        element: <CurrentInformationPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.forecastMaterials,
        element: <ForecastMaterialsPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.incidentReport,
        element: <IncidentReportPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.contacts,
        element: <ContactsPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.notFound,
        element: <NotFoundPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: '/monitoring/:dangerousProcesses',
        element: <MonitoringPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.aboutESKMP,
        element: <AboutPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.maps,
        element: <MapLayout />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.news,
        element: <NewsPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: `${AppRoutes.news}/:id`,
        element: <CurrentNewsPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: AppRoutes.allMaps,
        element: <AllMaps />,
        errorElement: <NotFoundPage />,
      },
    ],
  },
  {
    path: AppRoutes.disasterMap,
    element: <MapLayers />,
    errorElement: <NotFoundPage />,
  },
]);
