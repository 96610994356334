export enum navbarAppRoutes {
  default = '/',
  notFound = '/not-found',
  monitoring = '/monitoring',
  naturalDisasters = '/natural-disasters',
  forecastMaterials = '/forecast-materials',
  dataPlatform = '/data-platform',
  aboutESKMP = '/about-ESKMP',
  maps = '/maps/all',
  staffAccess = '/staff-access',
  cmbData = 'https://geo.cmbbs.okm.kg/',
  contacts = '/contacts',
}
