import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { Container } from '@modules/container';
import { NewsInfo } from '@modules/news/ui/NewsInfo';
import { NewsInfoHeader } from '@modules/news/ui/NewsInfoHeader';
import { SectionTitle } from '@modules/sectionTitle';

export const NewsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionTitle paddingBottom="1rem" title={t('news.title')} />
      <Flex gap={20} vertical>
        <NewsInfoHeader />
        <NewsInfo />
      </Flex>
    </Container>
  );
};
