import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IContacts, IContactsState } from '../interfaces/ContactsState';
import { ContactsApi } from './contactsApi';
import { ContactsSliceConstants } from './contactsSliceConstants';

const initialState: IContactsState = {
  messages: [],
  loading: false,
  error: null,
};

export const SendMessage = createAsyncThunk(
  ContactsSliceConstants.SendMessage,
  async (values: IContacts, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post<Awaited<IContacts>>(
        ContactsApi.message,
        values,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const ContactsSlice = createSlice({
  name: 'ContactsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        SendMessage.fulfilled,
        (state: IContactsState): IContactsState => {
          return {
            ...state,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(SendMessage.pending, (state: IContactsState): IContactsState => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(
        SendMessage.rejected,
        (state: IContactsState, { payload }: any): IContactsState => {
          return {
            ...state,
            loading: false,
            error: payload,
          };
        },
      );
  },
});

export default ContactsSlice.reducer;
