import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Form,
  message,
  Pagination,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';

import { Container } from '@modules/container';
import { SectionTitle } from '@modules/sectionTitle';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { MapStateSelector } from '../domain/store/selector';
import { getCmbLayers, getDistrict, getRegions } from '../domain/store/slice';
import { MapsItem } from './MapsItem';

export const AllMaps = () => {
  const [page, setPage] = useState(1);

  const [form] = Form.useForm();

  const {
    cmbLayers = [],
    district,
    region,
    countLayer,
    loading,
  } = useAppSelector(MapStateSelector);
  const dispatch = useAppDispatch();
  const valueRegion: number = Form.useWatch('region', form);
  const [reset, setReset] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (value: any) => {
    dispatch(getCmbLayers({ page, perPage: 10, ...value }));
    setReset(true);
  };

  const toggleResetFilter = () => {
    form.resetFields();
    dispatch(getCmbLayers({ page, perPage: 10 }));
    setReset(false);
  };

  useEffect(() => {
    dispatch(getCmbLayers({ page, perPage: 10 }));
  }, [page]);

  useEffect(() => {
    if (valueRegion) {
      form.setFieldsValue({ district: undefined });
      dispatch(getDistrict(valueRegion));
    } else {
      dispatch(getRegions());
    }
  }, [valueRegion]);

  return (
    <Container>
      <SectionTitle title="Карты" />
      {contextHolder}
      <Form
        form={form}
        name="contacts"
        style={{ width: 'auto' }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className="mt-10"
      >
        <Row gutter={[20, 0]}>
          <Col span={6}>
            <Form.Item name="region" rules={[{ required: true }]}>
              <Select
                className="w-[300px]"
                allowClear
                options={region}
                placeholder="Область"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="district">
              <Select
                allowClear
                className="w-[300px]"
                options={district}
                placeholder="Район"
              />
            </Form.Item>
          </Col>
          <Form.Item>
            <Button htmlType="submit">Отправить</Button>
          </Form.Item>
          {reset && (
            <Form.Item className="ml-2">
              <Button onClick={toggleResetFilter}>Сбросить</Button>
            </Form.Item>
          )}
        </Row>
      </Form>
      <Row className="py-8" gutter={[20, 80]}>
        {!loading ? (
          cmbLayers.map((item) => (
            <Col key={item.id} span={6}>
              <MapsItem item={item} showInfo />
            </Col>
          ))
        ) : (
          <Spin />
        )}
        {cmbLayers.length ? (
          <Col span={24}>
            <Row justify="end">
              <Col span={24}>
                <Pagination
                  pageSize={10}
                  current={page}
                  onChange={setPage}
                  // TODO remove to total items from back
                  total={countLayer}
                  showSizeChanger={false}
                />
              </Col>
            </Row>
          </Col>
        ) : (
          <div className="flex items-center justify-center">
            <Typography className="text-[2rem]">Карты отсутствуют</Typography>
          </div>
        )}
      </Row>
    </Container>
  );
};
