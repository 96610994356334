import { ReactComponent as ArrowLeft } from '@assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/arrow-right.svg';
import { ReactComponent as Calendar } from '@assets/icons/calendar.svg';
import { ReactComponent as CollapseDown } from '@assets/icons/collapseDown.svg';
import { ReactComponent as CollapseUp } from '@assets/icons/collapseUp.svg';
import { ReactComponent as Copy } from '@assets/icons/copy.svg';
import { ReactComponent as Dropdown } from '@assets/icons/dropdownIcon.svg';
import { ReactComponent as EyeOutlined } from '@assets/icons/eyeOutlined.svg';
import { ReactComponent as Fires } from '@assets/icons/fires.svg';
import { ReactComponent as Information } from '@assets/icons/information.svg';
import { ReactComponent as Lake } from '@assets/icons/lake.svg';
import { ReactComponent as Location } from '@assets/icons/location.svg';
import { ReactComponent as Minus } from '@assets/icons/minus.svg';
import { ReactComponent as News } from '@assets/icons/news.svg';
import { ReactComponent as NewsShare } from '@assets/icons/newsShare.svg';
import { ReactComponent as Plus } from '@assets/icons/plus.svg';
import { ReactComponent as Point } from '@assets/icons/point.svg';
import { ReactComponent as Right } from '@assets/icons/rightIcon.svg';
import { ReactComponent as Rockfalls } from '@assets/icons/rockfallsIcon.svg';
import { ReactComponent as Share } from '@assets/icons/share.svg';
import { ReactComponent as ShortArrowLeft } from '@assets/icons/shortArrowLeft.svg';
import { ReactComponent as ShortArrowRight } from '@assets/icons/shortArrowRight.svg';
import { ReactComponent as Telegram } from '@assets/icons/tg.svg';
import { ReactComponent as UploadFile } from '@assets/icons/uploadFile.svg';
import { ReactComponent as Whatsapp } from '@assets/icons/whatsapp.svg';

import { IIcons } from './interface';

export const icons: IIcons = {
  dropdown: Dropdown,
  plus: Plus,
  minus: Minus,
  eyeOutlined: EyeOutlined,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  information: Information,
  point: Point,
  news: News,
  calendar: Calendar,
  location: Location,
  rockfalls: Rockfalls,
  collapseDown: CollapseDown,
  collapseUp: CollapseUp,
  uploadFile: UploadFile,
  shortArrowRight: ShortArrowRight,
  shortArrowLeft: ShortArrowLeft,
  right: Right,
  share: Share,
  copy: Copy,
  tg: Telegram,
  whatsapp: Whatsapp,
  newsShare: NewsShare,
  fires: Fires,
  lake: Lake,
};
