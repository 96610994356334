import { useState } from 'react';
import { Link } from 'react-router-dom';

import { message, Tooltip, Typography } from 'antd';

import { useNormalizeDate } from '@hooks/useNormalizeDate';
import { useShare } from '@modules/home/services';
import { INewsItem } from '@modules/news/domain/interface/newsState';
import { Icon } from '@ui-kit/icon';
import dayjs from 'dayjs';

interface IInformationProps extends INewsItem {
  shareVisible?: boolean;
  contentVisible?: boolean;
  photoVisible?: boolean;
}

const { Paragraph } = Typography;

export const AllNewsCard: React.FC<IInformationProps> = ({
  date,
  created,
  title,
  content,
  id,
  shareVisible = false,
  contentVisible = true,
  photoVisible = true,
  code,
  photo,
}) => {
  const URL = process.env.REACT_APP_BASE_URL ?? '';
  const [open, setOpen] = useState(false);

  const shareArray = useShare({
    url: `${window.location.href}news/${code}`,
    title,
    email: { body: content },
    size: 20,
    round: true,
  });

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleCopy = (textToCopy: any) => {
    try {
      navigator.clipboard.writeText(
        `${window.location.href}all-information/${textToCopy}`,
      );
      message.info('URL скопирован');
    } catch (e: any) {
      message.error(e.message);
    }
  };

  return (
    <div className="mb-[32px] border-b-[1px] border-borderColor border-solid pb-8 flex items-start">
      {photoVisible && photo[0] && (
        <div className="flex-1 w-[280px] min-w-[280px] max-w-[280px] min-h-[120px] h-[120px] mr-4">
          <img
            src={`${URL}/useful-resources/file?path=${photo[0].path}`}
            alt="News"
            className="w-full h-full object-cover"
          />
        </div>
      )}
      <div className="flex-1 flex flex-col justify-center">
        <h2 className="font-700 text-[1.143rem] text-primaryGray">
          <Link to={`/news/${code}`} onClick={(e) => e.stopPropagation()}>
            {title}
          </Link>
        </h2>
        <div>
          {contentVisible ? (
            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Paragraph>
          ) : (
            ''
          )}
        </div>
        <div className="flex items-center justify-between mt-[15px]">
          <div className="flex gap-2 items-center text-[#0000005E] font-semibold">
            <Icon type="calendar" className="w-[16px]" />
            {useNormalizeDate(date || created)}
          </div>
          {shareVisible ? (
            <div className="flex gap-2 items-center">
              <Tooltip
                open={open}
                title={
                  <div className="flex justify-center items-center gap-2">
                    {shareArray.map((item) => item.button)}
                  </div>
                }
                onOpenChange={handleOpenChange}
                placement="bottom"
                trigger={['click']}
              >
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenChange(!open);
                  }}
                >
                  <Icon
                    type="share"
                    className="cursor-pointer w-[26px] defaultIcons"
                  />
                </button>
              </Tooltip>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleCopy(code);
                }}
              >
                <Icon
                  type="copy"
                  className="cursor-pointer w-[26px] defaultIcons"
                />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
