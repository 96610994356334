import { useParams } from '@hooks/useParams/useParams';
import { axiosInstance } from '@libs/http';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  IGetHomeInformation,
  IGetInformation,
  IInformation,
  IInformationState,
} from '../interface/InformationState';
import { InformationApi } from './informationApi';
import { InformationSliceConstants } from './informationSliceConstants';

const initialState: IInformationState = {
  information: [],
  totalInformation: 0,
  currentPage: 1,
  perPage: 3,
  date: dayjs().format('YYYY-MM-DD'),
  loading: false,
  error: null,
  currentInformation: null,
  currentInformationLoading: false,
  selectedParams: {},
  latestNews: [],
  totalLatest: 0,
  homeInformation: [],
  totalHomeInformation: 0,
};

export const getNews = createAsyncThunk(
  InformationSliceConstants.GetNews,
  async ({ id, lang }: { id: string; lang: string }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get<Awaited<IInformation>>(
        `${InformationApi.information}/${id}?lang=${lang}&code=${id}`,
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getLatestInformation = createAsyncThunk(
  InformationSliceConstants.GetLatestInformation,
  async function (
    params: { page?: number; perPage?: number; created?: string; lang: string },
    { rejectWithValue },
  ) {
    const urlParams = useParams(params);

    try {
      const { data } = await axiosInstance.get<Awaited<IGetHomeInformation>>(
        `${InformationApi.information}/proposal-info${urlParams.toString()}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getHomeInformation = createAsyncThunk(
  InformationSliceConstants.GetHomeInformation,
  async function (
    params: { page?: number; perPage?: number; date?: string; lang: string },
    { rejectWithValue },
  ) {
    const urlParams = useParams(params);

    try {
      const { data } = await axiosInstance.get<Awaited<IGetHomeInformation>>(
        `${InformationApi.information}/date${urlParams.toString()}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getInformation = createAsyncThunk(
  InformationSliceConstants.GetInformation,
  async function (
    params: { page?: number; perPage?: number; created?: string; lang: string },
    { rejectWithValue },
  ) {
    const urlParams = useParams(params);

    try {
      const { data } = await axiosInstance.get<Awaited<IGetInformation>>(
        `${InformationApi.information}${urlParams.toString()}`,
      );
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const InformationSlice = createSlice({
  name: 'InformationSlice',
  initialState,
  reducers: {
    changeCurrentPage: (state: IInformationState, { payload }) => {
      state.currentPage = payload;
    },
    changeCurrentDate: (state: IInformationState, { payload }) => {
      state.date = payload;
    },
    changeCurrentInformation: (state) => {
      state.currentInformation = null;
    },
    changeSelectParams: (
      state,
      { payload }: PayloadAction<Record<string, any>>,
    ) => {
      state.selectedParams = { ...state.selectedParams, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLatestInformation.fulfilled,
        (state, { payload }): IInformationState => {
          return {
            ...state,
            latestNews: payload.item,
            totalLatest: payload.count,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(getLatestInformation.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getLatestInformation.rejected, (state, { payload }: any) => {
        return {
          ...state,
          loading: false,
          error: payload,
        };
      });
    builder
      .addCase(
        getInformation.fulfilled,
        (state, { payload }): IInformationState => {
          return {
            ...state,
            information: payload.items,
            totalInformation: payload.count,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(getInformation.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getInformation.rejected, (state, { payload }: any) => {
        return {
          ...state,
          loading: false,
          error: payload,
        };
      });
    builder
      .addCase(getNews.fulfilled, (state, { payload }) => {
        return {
          ...state,
          currentInformationLoading: false,
          currentInformation: payload,
        };
      })
      .addCase(getNews.pending, (state) => {
        return {
          ...state,
          currentInformationLoading: true,
        };
      })
      .addCase(getNews.rejected, (state, { payload }: any) => {
        return {
          ...state,
          currentInformationLoading: false,
        };
      });
    builder
      .addCase(
        getHomeInformation.fulfilled,
        (state, { payload }): IInformationState => {
          return {
            ...state,
            homeInformation: payload.item,
            totalHomeInformation: payload.count,
            loading: false,
            error: null,
          };
        },
      )
      .addCase(getHomeInformation.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getHomeInformation.rejected, (state, { payload }: any) => {
        return {
          ...state,
          loading: false,
          error: payload,
        };
      });
  },
});

export default InformationSlice.reducer;
export const {
  changeCurrentPage,
  changeCurrentInformation,
  changeCurrentDate,
  changeSelectParams,
} = InformationSlice.actions;
