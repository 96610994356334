import { ISectionTitle } from '@modules/sectionTitle/ui/interface';

export const SectionTitle: React.FC<ISectionTitle> = ({
  title,
  fontSize = 2,
  fontWeight = 500,
  paddingBottom = 0,
}) => {
  return (
    <p
      style={{
        fontSize: `${fontSize}rem`,
        fontWeight,
        paddingBottom,
      }}
    >
      {title}
    </p>
  );
};
