import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Col, Divider, Pagination, Row } from 'antd';

import { NavbarStateSelector } from '@modules/header/domain/store/selector';
import { NewsStateSelector } from '@modules/news/domain/store/selector';
import { getAllNews } from '@modules/news/domain/store/slice';
import { SectionTitle } from '@modules/sectionTitle';
import { Icon } from '@ui-kit/icon';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AllNewsCard } from './AllNewsCard';

export const AllNews: React.FC = () => {
  const [page, setPage] = useState(1);
  const perPage = 6;

  const dispatch = useAppDispatch();
  const { allNews, loading, totalNews } = useAppSelector(NewsStateSelector);

  const { language } = useAppSelector(NavbarStateSelector);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      getAllNews({
        perPage,
        lang: language,
        page,
      }),
    );
  }, [language, page]);

  return (
    <div className="w-auto">
      <Divider />
      <div className="mb-[32px]">
        <SectionTitle title={t('news.title')} />
        <Link
          to="/news"
          className="text-primaryBlue w-max float-end flex items-center gap-[8px] justify-end border"
        >
          {t('news.newsLink')} <Icon className="w-[14px]" type="right" />
        </Link>
      </div>
      <Row align="middle">
        <Col span={24}>
          {!totalNews && !loading ? (
            <div className="flex items-center justify-center">
              <h3 className="text-[2rem] font-semibold">
                {t('news.emptyNews')}
              </h3>
            </div>
          ) : (
            <div className="flex flex-col justify-between h-full  min-h-[400px]">
              {allNews?.map((info) => (
                <AllNewsCard
                  key={info.id}
                  {...info}
                  shareVisible
                  contentVisible={false}
                />
              ))}
              {totalNews ? (
                <Pagination
                  className="flex justify-end mt-4"
                  pageSize={perPage}
                  current={page}
                  onChange={setPage}
                  total={totalNews}
                />
              ) : null}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
