import { useTranslation } from 'react-i18next';

import { Flex } from 'antd';

import { Container } from '@modules/container';
import { AllInformationHeader } from '@modules/information/ui/AllInformationHeader';
import { News } from '@modules/information/ui/News';
import { SectionTitle } from '@modules/sectionTitle';

export const AllInformationPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionTitle paddingBottom="1rem" title={t('stormWarning.title')} />
      <Flex gap={20} vertical>
        <AllInformationHeader />
        <News />
      </Flex>
    </Container>
  );
};
