import { NavLink } from 'react-router-dom';

import { useNavbarRoutes } from '@modules/header/services';

export const Navbar: React.FC = () => {
  const navRoutes = useNavbarRoutes();

  return (
    <ul className="flex gap-[5px] lg:gap-[36px] flex-col lg:flex-row">
      {navRoutes.map((item) => (
        <li
          key={item.route}
          className="text-[#565656] text-[1rem] whitespace-nowrap flex items-center justify-center"
        >
          <NavLink
            target={item.blank ? '_blank' : '_self'}
            to={item.path}
            className={({ isActive }) =>
              isActive
                ? 'text-center mx-2 px-[8px] leading-0 font-golosUI font-bold text-primaryBlue border-b-4 border-solid flex items-center h-full'
                : 'px-[8px] mx-2 py-[16px] font-golosUI leading-0 hover:text-primaryBlue border-b-4 border-transparent border-solid flex items-center h-full'
            }
          >
            {item.route}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
