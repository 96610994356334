import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Form,
  Input,
  message,
  Select,
  Space,
  Tooltip,
  Typography,
  Upload,
} from 'antd';

import { InfoCircleFilled, RedoOutlined } from '@ant-design/icons';
import {
  useLocation,
  useReportIncident,
  useSelectIncidentData,
  useUploadProps,
} from '@modules/reportIncident/services';
import { Icon } from '@ui-kit/icon';

import { IReportIncident } from '../domain/interfaces/ReportIncidentState';

const { TextArea } = Input;
const { Dragger } = Upload;

export const IncidentReportForm: React.FC = () => {
  const { userLocation, getLocation } = useLocation();
  const selectItems = useSelectIncidentData();
  const draggerProps = useUploadProps();
  const { handleSubmitReportIncident } = useReportIncident();
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onFinish = (values: IReportIncident) => {
    handleSubmitReportIncident({ ...values, phone: `+${values.phone}` }).then(
      (value) => {
        if (value?.meta?.requestStatus === 'fulfilled') {
          message.info('Сообщение отправлено!');
        } else {
          message.error('Что-то пошло не так!');
        }
      },
    );

    form.resetFields(['name', 'phone', 'type', 'message', 'images']);
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (userLocation.latitude)
      form.setFieldValue(
        'geo',
        `${userLocation.latitude},${userLocation.longitude}`,
      );
  }, [userLocation]);

  return (
    <Form
      name="contacts"
      form={form}
      style={{ width: 'auto' }}
      onFinish={onFinish}
      autoComplete="off"
      className="mt-10 mb-[115px]"
    >
      <Form.Item
        label={t('incidentReport.fullName')}
        name="name"
        labelCol={{ span: 24 }}
        rules={[{ required: true, message: 'Пожалуйста заоплните ФИО!' }]}
      >
        <Input
          placeholder={t('incidentReport.enterFullName')}
          className="rounded-[5px] p-[16px]"
        />
      </Form.Item>

      <Form.Item
        label={t('incidentReport.phone')}
        name="phone"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: 'Пожалуйста заполните ваш телефон!',
          },
        ]}
      >
        <Input
          type="number"
          placeholder={t('incidentReport.enterPhone')}
          className="rounded-[5px] p-[16px] remove-arrow"
          onWheel={(e) => e.currentTarget.blur()}
        />
      </Form.Item>

      <Form.Item
        label={t('incidentReport.disasterType')}
        name="type"
        labelCol={{ span: 24 }}
      >
        <Select
          options={selectItems}
          placeholder={t('incidentReport.enterDisasterType')}
          className="h-[55px] p-[-16px]"
        />
      </Form.Item>

      <Form.Item
        label={
          <Space>
            <Typography>{t('incidentReport.location')}</Typography>
            <Tooltip
              title={
                <Space direction="vertical">
                  <Typography className="text-amber-400">
                    Пожалуйста, дайте разрешение на определение вашего
                    местоположения, иначе вы не сможете сообщить о происшествии
                  </Typography>
                </Space>
              }
            >
              <InfoCircleFilled style={{ fontSize: '24px' }} />
            </Tooltip>
          </Space>
        }
        name="geo"
        labelCol={{ span: 24 }}
        rules={[{ required: true }]}
      >
        <Input
          suffix={
            <Tooltip title="Обновить локацию!">
              <RedoOutlined onClick={getLocation} />
            </Tooltip>
          }
          readOnly
          className="rounded-[5px] p-[16px]"
        />
      </Form.Item>

      <Form.Item
        label={t('incidentReport.message')}
        name="message"
        labelCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: 'Пожалуйста заполните текст сообщения!',
          },
        ]}
      >
        <TextArea
          placeholder={t('incidentReport.enterMessage')}
          rows={4}
          className="rounded-[5px] p-[16px] placeholder-shown:resize-none"
        />
      </Form.Item>

      <Form.Item name="images">
        <Dragger {...draggerProps}>
          <div className="flex justify-center items-center gap-[11px] h-[88px]">
            <Icon className="h-[15px] w-[20px]" type="uploadFile" />
            <p className="ant-upload-hint">{t('incidentReport.files')}</p>
          </div>
        </Dragger>
      </Form.Item>

      <Form.Item>
        <button
          className="text-base rounded-lg w-full h-[63px] bg-primaryBlue text-white font-semibold mt-[48px]"
          type="submit"
        >
          {t('incidentReport.send')}
        </button>
      </Form.Item>
    </Form>
  );
};
