import { Link } from 'react-router-dom';

import { Typography } from 'antd';

import { IUsefulResource } from '../domain/interfaces/HomepageState';

const URL = process.env.REACT_APP_BASE_URL ?? '';

export const UsefulResourcesCard: React.FC<IUsefulResource> = ({
  title,
  url,
  imageUrl,
  logo,
}) => {
  return (
    <Link to={url} target="_blank" className="relative">
      <div className="p-5">
        <div>
          <img
            src={`${URL}/useful-resources/file?path=${logo}`}
            alt="logo"
            className="w-[60px] h-[60px] rounded-full absolute top-[15px] left-[20px]"
          />
        </div>
        <div className="w-[300] h-[200px] mb-[8px]">
          <img
            src={`${URL}/useful-resources/file?path=${imageUrl}`}
            alt={title}
            style={{ maxWidth: '300px', maxHeight: '230px' }}
            className="rounded-[20px] h-[100%] object-center w-[100%] object-contain"
          />
        </div>
        <div className="absolute px-[10px] top-[150px] bottom-[8px] left-0 right-0 textLayer rounded-[20px]">
          <Typography className="text-white ml-2 pt-4">{title}</Typography>
        </div>
      </div>
    </Link>
  );
};
